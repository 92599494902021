export const PROJECT_ID =
  process.env.PROJECT_ID || process.env.REACT_APP_PROJECT_ID;

let _tipiPazienti;
let _tipiPazientiInfo;
let _tipiControlli = ["Controllo"];
let _tipiControlliInfo = {
  Controllo: {
    label: "Controllo",
    i18nKey: "tipo_controllo_Controllo",
    hasPazienteDiRiferimento: false
  }
};

let hasPartecipantiControlli;
let hasPartecipantiOperatori;

let name;
let description;
let icon;
let emailAccessoAnomalo;

let askInformazioniPersonaliPaziente;

let db;

let supportedLngs = ["en", "it", "de", "es", "fr"];
let lng = "en";

let haESM = true;

let numeroDiVolteESM = 1;

if (PROJECT_ID == "diapason") {
  _tipiPazienti = ["Residenziale", "Ambulatoriale"];
  _tipiPazientiInfo = {
    Residenziale: {
      label: "Residenziale",
      i18nKey: "tipo_paziente_Residenziale",
      hasOperatoreDiRiferimento: true
    },
    Ambulatoriale: {
      label: "Ambulatoriale",
      i18nKey: "tipo_paziente_Ambulatoriale",
      hasOperatoreDiRiferimento: false
    }
  };

  name = "Diapason";
  description = "Diapason";
  icon = "/icon/diapason-logo.png";
  emailAccessoAnomalo = "...";
  db = "diapason";
  hasPartecipantiControlli = true;
  hasPartecipantiOperatori = true;
  askInformazioniPersonaliPaziente = true;
} else if (PROJECT_ID == "bipcom") {
  _tipiPazienti = ["Pazienti"];
  _tipiPazientiInfo = {
    Pazienti: {
      label: "Pazienti",
      i18nKey: "tipo_paziente_Pazienti",
      hasOperatoreDiRiferimento: true
    }
  };

  name = "BIPCOM";
  description =
    "Medical Comorbidities in Bipolar Disorder: Clinical Validation of Risk Factors and Biomarkers to Improve Prevention and Treatment";
  icon = "/icon/bipcom-logo.png";
  emailAccessoAnomalo =
    "mmagno@fatebenefratelli.eu, ecaselani@fatebenefratelli.eu";

  db = "bipcom";
  hasPartecipantiControlli = true;
  hasPartecipantiOperatori = false;
  askInformazioniPersonaliPaziente = true;

  numeroDiVolteESM = 3;
} else if (PROJECT_ID == "bipcom-mrat") {
  _tipiPazienti = ["Pazienti"];
  _tipiPazientiInfo = {
    Pazienti: {
      label: "Pazienti",
      i18nKey: "tipo_paziente_Pazienti",
      hasOperatoreDiRiferimento: true
    }
  };

  name = "MRAT Study";
  description = "";
  icon = "/icon/bipcom-mrat-logo.png";
  emailAccessoAnomalo =
    "mmagno@fatebenefratelli.eu, ecaselani@fatebenefratelli.eu";

  db = "bipcom-mrat";
  hasPartecipantiControlli = false;
  hasPartecipantiOperatori = false;
  askInformazioniPersonaliPaziente = false;
  haESM = false;
} else if (PROJECT_ID == "riskmet") {
  _tipiPazienti = ["Paziente Pediatrico", "Paziente Adulto"];
  _tipiPazientiInfo = {
    "Paziente Adulto": {
      label: "Paziente Adulto",
      i18nKey: "tipo_paziente_Adulto",
      hasOperatoreDiRiferimento: true
    },
    "Paziente Pediatrico": {
      label: "Paziente Pediatrico",
      i18nKey: "tipo_paziente_Pediatrico",
      hasOperatoreDiRiferimento: true
    }
  };

  _tipiControlliInfo = {
    "Controllo sano": {
      label: "Controllo sano",
      i18nKey: "tipo_controllo_Controllo_sano",
      hasPazienteDiRiferimento: false
    },
    "Genitore paziente adulto": {
      label: "Genitore paziente adulto",
      i18nKey: "tipo_controllo_Genitore_paziente_adulto",
      hasPazienteDiRiferimento: true
    },
    "Genitore paziente pediatrico": {
      label: "Genitore paziente pediatrico",
      i18nKey: "tipo_controllo_Genitore_paziente_pediatrico",
      hasPazienteDiRiferimento: true
    },
    "Fratello/sorella paziente adulto": {
      label: "Fratello/sorella paziente adulto",
      i18nKey: "tipo_controllo_Fratello_sorella_adulto",
      hasPazienteDiRiferimento: true
    },
    "Fratello/sorella paziente pediatrico": {
      label: "Fratello/sorella paziente pediatrico",
      i18nKey: "tipo_controllo_Fratello_sorella_pediatrico",
      hasPazienteDiRiferimento: true
    }
  };
  _tipiControlli = Object.keys(_tipiControlliInfo);

  name = "RISKMET";
  description =
    "METABOLIC SYNDROME IN PEOPLE TREATED WITH ANTIPSYCHOTICS: A MULTIMETHOD INVESTIGATION OF GENETIC, BEHAVIOURAL AND ENVIRONMENTAL RISK FACTORS (RISKMet)";
  icon = "/icon/riskmet-logo.png";
  emailAccessoAnomalo =
    "mmagno@fatebenefratelli.eu, ecaselani@fatebenefratelli.eu";

  db = "riskmet";
  hasPartecipantiControlli = true;
  hasPartecipantiOperatori = false;
  askInformazioniPersonaliPaziente = true;
  supportedLngs = ["it"];
  lng = "it";

  numeroDiVolteESM = 2;
} else {
  console.error("UNKNOWN PROJECT_ID = " + process.env.PROJECT_ID);
  process.exit(1);
}

export const __tipiPazienti = _tipiPazienti;
export const __tipiPazientiInfo = _tipiPazientiInfo;

export const __tipiControlli = _tipiControlli;
export const __tipiControlliInfo = _tipiControlliInfo;

export const NAME = name;
export const DESCRIPTION = description;
export const ICON = icon;
export const EMAIL_PER_ACCESSO_ANOMALO = emailAccessoAnomalo;

export const DB = db;

export const HAS_PARTECIPANTI_CONTROLLI = hasPartecipantiControlli;
export const HAS_PARTECIPANTI_OPERATORI = hasPartecipantiOperatori;

export const ASK_INFORMAZIONI_PERSONALI_PAZIENTE_E_ALTRE_COSE = askInformazioniPersonaliPaziente;

export const LNG = lng;
export const SUPPORTED_LNGS = supportedLngs;

export const HA_ESM = haESM;

export const NUMERO_DI_VOLTE_ESM = numeroDiVolteESM;
