export default [
  {
    type: "SCHEDA SOGGETTO",
    name: "SCHEDA PAZIENTE ",
    codiceDataCompletamento: "DATE_00",
    filtro: null,
    domande: [
      {
        key: "DATE_00",
        label: "Data",
        type: "date"
      },
      {
        key: "ROLE",
        label: "Ruolo professionale ricoperto in struttura",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Psichiatra"
          },
          {
            value: 2,
            label: "TERP"
          },
          {
            value: 3,
            label: "Psicologo/psicoterapeuta"
          },
          {
            value: 4,
            label: "Altro"
          }
        ]
      },
      {
        key: "ROLE_a",
        label: "Specificare il ruolo",
        if: [
          {
            key: "ROLE",
            comparator: "=",
            value: 4
          }
        ],
        type: "free-text"
      },
      {
        key: "Tipo_soggetto",
        label: "Tipo di partecipante",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Paziente adulto"
          },
          {
            value: 2,
            label: "Paziente pediatrico"
          },
          {
            value: 3,
            label: "Genitore Paziente Adulto"
          },
          {
            value: 4,
            label: "Genitore Paziente Pediatrico"
          },
          {
            value: 5,
            label: "Fratello/sorella pediatrico"
          },
          {
            value: 6,
            label: "Fratello/sorella adulto"
          },
          {
            value: 7,
            label: "Controllo"
          }
        ]
      },
      {
        key: "PATIENT_FORM_rel",
        label: "Tipo di relazione",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Genitore paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Padre"
          },
          {
            value: 2,
            label: "Madre"
          },
          {
            value: 3,
            label: "Fratello"
          },
          {
            value: 4,
            label: "Sorella"
          }
        ]
      },
      {
        key: "PATIENT_FORM_rel1",
        label: "Indicare codice del parente",
        if: null,
        type: "free-text"
      },
      {
        key: "PATIENT_FORM_Age",
        label: "Età",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "6 - 17."
          },
          {
            value: 2,
            label: "18-24"
          },
          {
            value: 3,
            label: "25-34"
          },
          {
            value: 4,
            label: "35-44"
          },
          {
            value: 5,
            label: "45-54"
          },
          {
            value: 6,
            label: "55-65"
          }
        ]
      },
      {
        key: "PATIENT_FORM_Sex",
        label: "Sesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Maschio"
          },
          {
            value: 2,
            label: "Femmina"
          }
        ]
      },
      {
        key: "PATIENT_FORM_Education",
        label: "Educazione",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Licenza elementare"
          },
          {
            value: 2,
            label: "Licenza media inferiore"
          },
          {
            value: 3,
            label: "Diploma professionale (3 anni)"
          },
          {
            value: 4,
            label: "Diploma media superiore"
          },
          {
            value: 5,
            label: "Laurea triennale"
          },
          {
            value: 6,
            label: "Laurea specialistica"
          },
          {
            value: 7,
            label: "Specializzazione"
          },
          {
            value: 8,
            label: "Non frequenta scuola"
          }
        ]
      },
      {
        key: "PATIENT_FORM_EducationPed",
        label: "Quale classe frequenta?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Pediatrico"
          }
        ],
        type: "free-text"
      },
      {
        key: "PATIENT_FORM_EducationPed2",
        label: "Sostegno scolastico",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PATIENT_FORM_maritalstatus",
        label: "Stato civile ",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo sano"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Genitore paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Celibe / nubile, che non convive con un partner stabile"
          },
          {
            value: 2,
            label: "Coniugato/a"
          },
          {
            value: 3,
            label: "Celibe/nubile, che convive con un partner stabile"
          },
          {
            value: 4,
            label: "Separato/a – divorziato/a"
          },
          {
            value: 5,
            label: "Vedovo/a"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_physicalact",
        label: "Pratica regolarmente attività fisica?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_physicalactspec",
        label: "Specificare attività fisica svolta",
        if: [
          {
            key: "SCHEDA_SOGGETTO_physicalact",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "SCHEDA_SOGGETTO_physicalacttime",
        label: "Con quale regolarità?",
        if: [
          {
            key: "SCHEDA_SOGGETTO_physicalact",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1  volta a settimana"
          },
          {
            value: 2,
            label: "2 volte a settimana"
          },
          {
            value: 3,
            label: "3 o più volte a settimana"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_occupation",
        label: "Condizione lavorativa attuale",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo sano"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Genitore paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente pediatrico"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lavoro a tempo pieno"
          },
          {
            value: 2,
            label: "Lavoro a tempo parziarle"
          },
          {
            value: 3,
            label: "Corso di formazione lavorativa"
          },
          {
            value: 4,
            label: "Studente/essa"
          },
          {
            value: 5,
            label: "Casalinga/o"
          },
          {
            value: 6,
            label: "Disoccupato o in cerca di prima occupazione"
          },
          {
            value: 7,
            label:
              "Pensionato che non svolge alcuna attività retribuita (compreso fruitore di pensione di invalidità)"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_income",
        label: "Qual è il reddito principale?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo sano"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Genitore paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente pediatrico"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lavoro dipendente"
          },
          {
            value: 2,
            label: "Lavoro autonomo"
          },
          {
            value: 3,
            label: "Pensioni"
          },
          {
            value: 4,
            label:
              "Capitale o altri redditi (case in affitto, attività familiari, etc.)"
          },
          {
            value: 5,
            label: "Nessuno"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_fam",
        label: "Con chi vive attualmente?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo sano"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Genitore paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente adulto"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Fratello/sorella paziente pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Da solo"
          },
          {
            value: 2,
            label: "Coniuge con o senza figli"
          },
          {
            value: 3,
            label: "Altri familiari"
          },
          {
            value: 4,
            label: "Amici"
          },
          {
            value: 5,
            label: "Con altre persone"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_comp",
        label: "Chi compila?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Pediatrico"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Genitore"
          },
          {
            value: 2,
            label: "Parente non genitore"
          },
          {
            value: 3,
            label: "Tutore"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_ProfGen",
        label: "Professione dei genitori se presenti",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Paziente Pediatrico"
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "SIMPSON",
    name:
      "SCALA DI SIMPSON/ANGUS MODIFICATA PER LA\nVALUTAZIONE DEGLI EFFETTI EXTRAPIRAMIDALI",
    codiceDataCompletamento: "DATE_S",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_S",
        label: "Data",
        type: "date"
      },
      {
        key: "SIM_1",
        label: "Andatura",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_2",
        label: "Caduta delle braccia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_3",
        label: "Mobilità delle spalle",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_4",
        label: "Rigidità del gomito",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_5",
        label: "Rigidità del polso o fissazione della posizione",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_6",
        label: "Rotazione della testa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_7",
        label: "Segno della Glabella",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_8",
        label: "Tremore",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_9",
        label: "Salivazione",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_10",
        label: "Acatisia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 0
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: 2
          },
          {
            value: 4,
            label: 3
          },
          {
            value: 5,
            label: 4
          },
          {
            value: 6,
            label: "NV"
          }
        ]
      },
      {
        key: "SIM_tot",
        label: "Punteggio totale",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 40
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "STATO FISICO",
    name: "STATO FISICO",
    codiceDataCompletamento: "DATE_01",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_01",
        label: "Data ",
        type: "date"
      },
      {
        key: "PHYSICAL_FORM_GSF_waist",
        label: "Circonferenza addominale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">102 cm (M); >88 cm (F)"
          },
          {
            value: 2,
            label: "<102 cm (M); <88 cm (F)"
          },
          {
            value: 3,
            label: ">90esimo percentile"
          },
          {
            value: 4,
            label: "<90esimo percentile"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_Weight",
        label: "Peso",
        if: null,
        type: "number",
        range: {
          from: 20,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_GSF_Height",
        label: "Altezza",
        if: null,
        type: "number",
        range: {
          from: 120,
          to: 200
        },
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_GSF_BMI",
        label: "BMI",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          specialType: "BMI",
          massa: "PHYSICAL_FORM_GSF_Weight",
          altezza: "PHYSICAL_FORM_GSF_Height",
          factor: 10000
        }
      },
      {
        key: "PHYSICAL_FORM_GSF_triglycerides",
        label: "Trigliceridi Elevati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">150 mg/dL"
          },
          {
            value: 2,
            label: "<150 mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_HDL",
        label: "Ridotto colesterolo HDL",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "<40 mg/dL (M); <50 mg/dL (F)"
          },
          {
            value: 2,
            label: ">40 mg/dL (M); >50 mg/dL (F)"
          },
          {
            value: 3,
            label: "<40 mg/dL"
          },
          {
            value: 4,
            label: ">40 mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_pressure",
        label: "Pressione arteriosa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">130/85 mmHg"
          },
          {
            value: 2,
            label: "<130/85 mmHg"
          },
          {
            value: 3,
            label: ">90esimo percentile per eta, sesso, altezza"
          },
          {
            value: 4,
            label: "<90esimo percentile per eta, sesso, altezza"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_glucose",
        label: "Glicemia a digiuno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">100 mg/dL"
          },
          {
            value: 2,
            label: "<100 mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_METS",
        label: "Diagnosi di MetS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_METS1",
        label: "Criteri soddisfatti",
        if: [
          {
            key: "PHYSICAL_FORM_GSF_METS",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Elevata circonferenza addominale"
          },
          {
            value: 2,
            label: "Glicemia a digiuno alta"
          },
          {
            value: 3,
            label: "Elevati trigliceridi"
          },
          {
            value: 4,
            label: "HDL Basso"
          },
          {
            value: 5,
            label: "Ipertensione"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_medcom",
        label: "Comorbidità mediche",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Diabete Mellito"
          },
          {
            value: 2,
            label: "Patologie cardiovascolari"
          },
          {
            value: 3,
            label: "Obesità"
          },
          {
            value: 4,
            label: "Disturbi renali"
          },
          {
            value: 5,
            label: "Condizioni neurologiche"
          },
          {
            value: 6,
            label: "Emicrania"
          },
          {
            value: 7,
            label: "Disturbi muscoloscheletrici"
          },
          {
            value: 8,
            label: "Osteporosi"
          },
          {
            value: 9,
            label: "Patologie endocrine"
          },
          {
            value: 10,
            label: "Altro"
          },
          {
            value: 11,
            label: "Nessuna"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_GSF_medcomspec",
        label: "Specify other comorbidities",
        if: [
          {
            key: "PHYSICAL_FORM_GSF_medcom",
            comparator: "=",
            value: 10,
            isMulti: true
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "STATO FISICO GSF",
    name: "STATO FISICO",
    codiceDataCompletamento: "DATE_STATO_FISICO_GSF",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo sano"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Genitore paziente adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Fratello/sorella paziente adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Fratello/sorella paziente pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_STATO_FISICO_GSF",
        label: "Data ",
        type: "date"
      },
      {
        key: "PHYSICAL_FORM_identita",
        label: "Specificare identità",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Soggetto di controllo"
          },
          {
            value: 2,
            label: "Genitore paziente adulto Mets+"
          },
          {
            value: 3,
            label: "Genitore paziente adulto Mets-"
          },
          {
            value: 4,
            label: "Genitore paziente pediatrico Mets+"
          },
          {
            value: 5,
            label: "Genitore paziente pediatrico Mets-"
          },
          {
            value: 6,
            label: "Fratello/sorella paziente adulto"
          },
          {
            value: 7,
            label: "Fratello/sorella paziente pediatrico"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_waist",
        label: "Circonferenza addominale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">102 cm (M); >88 cm (F)"
          },
          {
            value: 2,
            label: "<102 cm (M); <88 cm (F)"
          },
          {
            value: 3,
            label: ">90esimo percentile"
          },
          {
            value: 4,
            label: "<90esimo percentile"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_Weight",
        label: "Peso",
        if: null,
        type: "number",
        range: {
          from: 20,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_Height",
        label: "Altezza",
        if: null,
        type: "number",
        range: {
          from: 120,
          to: 200
        },
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_BMI",
        label: "BMI",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          specialType: "BMI",
          massa: "PHYSICAL_FORM_Weight",
          altezza: "PHYSICAL_FORM_Height",
          factor: 10000
        }
      },
      {
        key: "PHYSICAL_FORM_triglycerides",
        label: "Trigliceridi Elevati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">150 mg/dL"
          },
          {
            value: 2,
            label: "<150 mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_HDL",
        label: "Ridotto colesterolo HDL",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "<40 mg/dL (M); <50 mg/dL (F)"
          },
          {
            value: 2,
            label: ">40 mg/dL (M); >50 mg/dL (F)"
          },
          {
            value: 3,
            label: "<40 mg/dL"
          },
          {
            value: 4,
            label: ">40 mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_pressure",
        label: "Pressione arteriosa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">130/85 mmHg"
          },
          {
            value: 2,
            label: "<130/85 mmHg"
          },
          {
            value: 3,
            label: ">90esimo percentile per eta, sesso, altezza"
          },
          {
            value: 4,
            label: "<90esimo percentile per eta, sesso, altezza"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_glucose",
        label: "Glicemia a digiuno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">100 mg/dL"
          },
          {
            value: 2,
            label: "<100 mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_METS",
        label: "Diagnosi di MetS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_METS1",
        label: "Criteri soddisfatti",
        if: [
          {
            key: "PHYSICAL_FORM_METS",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Elevata circonferenza addominale"
          },
          {
            value: 2,
            label: "Glicemia a digiuno alta"
          },
          {
            value: 3,
            label: "Elevati trigliceridi"
          },
          {
            value: 4,
            label: "HDL Basso"
          },
          {
            value: 5,
            label: "Ipertensione"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_medcom",
        label: "Comorbidità mediche",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Diabete Mellito"
          },
          {
            value: 2,
            label: "Patologie cardiovascolari"
          },
          {
            value: 3,
            label: "Obesità"
          },
          {
            value: 4,
            label: "Disturbi renali"
          },
          {
            value: 5,
            label: "Condizioni neurologiche"
          },
          {
            value: 6,
            label: "Emicrania"
          },
          {
            value: 7,
            label: "Disturbi muscoloscheletrici"
          },
          {
            value: 8,
            label: "Osteporosi"
          },
          {
            value: 9,
            label: "Patologie endocrine"
          },
          {
            value: 10,
            label: "Altro"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_medcomspec",
        label: "Specify other comorbidities",
        if: [
          {
            key: "PHYSICAL_FORM_medcom",
            comparator: "=",
            value: 10,
            isMulti: true
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "FAM-GEN",
    name: "QUESTIONARIO SULLA FAMILIARITA' PER LE MALATTIE",
    codiceDataCompletamento: "DATA_FAM-GEN",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_FAM-GEN",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "FAM_GEN_0",
        label:
          "Qualcuno nella tua famiglia ha mai sofferto di Diabete Mellito?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_1",
        label: "Madre",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_1a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_1",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_2",
        label: "Padre",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN2a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_2",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_3",
        label: "Fratello/sorella",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN3a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_3",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_4",
        label: "Figlio/figlia",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN4a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_4",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_5",
        label: "Nonno/nonna paterna",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN5a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_5",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_6",
        label: "Nonno/nonna materna",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN6a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_6",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_7",
        label: "Zio/zia paterna",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN7a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_7",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_8",
        label: " Zio/zia materna",
        if: [
          {
            key: "FAM_GEN_0",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN8a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_8",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_99",
        label: "Qualcuno nella tua famiglia ha mai sofferto di Ipertensione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_9",
        label: "Madre",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_9a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_9",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_10",
        label: "Padre",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN10a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_10",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_12",
        label: "Fratello/sorella",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN12a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_12",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_13",
        label: "Figlio/figlia",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN13a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_13",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_14",
        label: "Nonno/nonna paterna",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN14a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_14",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_15",
        label: "Nonno/nonna materna",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN15a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_15",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_16",
        label: "Zio/zia paterna",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN16a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_16",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_17",
        label: "Zio/zia materna",
        if: [
          {
            key: "FAM_GEN_99",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN17a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_17",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_18",
        label: "Qualcuno nella tua famiglia ha mai sofferto di Dislipidemia?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_19",
        label: "Madre",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_19a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_19",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_20",
        label: "Padre",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN20a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_20",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_22",
        label: "Fratello/sorella",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN22a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_22",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_23",
        label: "Figlio/figlia",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN23a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_23",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_24",
        label: "Nonno/nonna paterna",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN24a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_24",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_25",
        label: "Nonno/nonna materna",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN25a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_25",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_26",
        label: "Zio/zia paterna",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN26a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_26",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_27",
        label: "Zio/zia materna",
        if: [
          {
            key: "FAM_GEN_18",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN27a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_27",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_28",
        label: "Qualcuno nella tua famiglia ha mai sofferto di Obesità?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_29",
        label: "Madre",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_29a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_29",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_30",
        label: "Padre",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN30a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_30",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_32",
        label: "Fratello/sorella",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN32a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_32",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_33",
        label: "Figlio/figlia",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN33a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_33",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_34",
        label: " Nonno/nonna paterna",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN34a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_34",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_35",
        label: "Nonno/nonna materna",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN35a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_35",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_36",
        label: "Zio/zia paterna",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN36a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_36",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_37",
        label: " Zio/zia materna",
        if: [
          {
            key: "FAM_GEN_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN37a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_37",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_38",
        label:
          "Qualcuno nella tua famiglia ha mai sofferto di malattie cardiovascolari?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_39",
        label: "Madre",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_39a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_39",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_40",
        label: "Padre",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN40a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_40",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_42",
        label: "Fratello/sorella",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN42a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_42",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_43",
        label: "Figlio/figlia",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN43a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_43",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_44",
        label: " Nonno/nonna paterna",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN44a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_44",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_45",
        label: " Nonno/nonna materna",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN45a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_45",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_46",
        label: " Zio/zia paterna",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN46a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_46",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_47",
        label: " Zio/zia materna",
        if: [
          {
            key: "FAM_GEN_38",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN47a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_47",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_48",
        label:
          "Qualcuno nella tua famiglia ha mai sofferto di malattie renali?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_49",
        label: "Madre",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_49a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_49",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_50",
        label: "Padre",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN50a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_50",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_52",
        label: "Fratello/sorella",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN52a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_52",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_53",
        label: "Figlio/figlia",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN53a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_53",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_54",
        label: " Nonno/nonna paterna",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN54a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_54",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_55",
        label: " Nonno/nonna materna",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN55a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_55",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_56",
        label: " Zio/zia paterna",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN56a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_56",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN_57",
        label: "Zio/zia materna",
        if: [
          {
            key: "FAM_GEN_48",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      },
      {
        key: "FAM_GEN57a",
        label: "E' in trattamento farmacologico?",
        if: [
          {
            key: "FAM_GEN_57",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non so"
          }
        ]
      }
    ]
  },
  {
    type: "SCHEDA STATUS PSICHIATRICO",
    name: "SCHEDA STATUS PSICHIATRICO ",
    codiceDataCompletamento: "DATE_03",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_03",
        label: "Date",
        type: "date"
      },
      {
        key: "PSICH_diagnosi",
        label: "Diagnosi del paziente",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Disturbi dello Spettro Schizofrenico"
          },
          {
            value: 2,
            label: "Disturbo Bipolare I"
          },
          {
            value: 3,
            label: "Disturbo Bipolare II"
          },
          {
            value: 4,
            label: "Disturbi del Neurosviluppo"
          }
        ]
      },
      {
        key: "PSICH_diagnosi_spec",
        label: "Specificare ",
        if: [
          {
            key: "PSICH_diagnosi",
            comparator: "=",
            value: 1
          },
          {
            key: "PSICH_diagnosi",
            comparator: "=",
            value: 4
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_Duration",
        label: "Durata di malattia ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 55
        },
        step: "any",
        auto: null
      },
      {
        key: "PSICH_ageonset",
        label: "A che età ha ricevuto la diagnosi? ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_hosp",
        label: "Ha mai subito ricoveri a causa del suo disturbo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 4,
            label: "1-2 years"
          }
        ]
      },
      {
        key: "PSICH_hospage",
        label: "A che età è stato ricoverato per la prima volta?",
        if: [
          {
            key: "PSICH_hosp",
            comparator: "=",
            value: 1
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_duration",
        label:
          "Durata complessiva approssimativa di tutti i ricoveri psichiatrici della vita (in Ospedali Psichiatrici, SPDC, case di cura psichiatriche, strutture residenziali) (sommare la durata dei ricoveri nelle varie strutture psichiatriche)",
        if: [
          {
            key: "PSICH_hosp",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "0-2 mesi"
          },
          {
            value: 2,
            label: "da 2 mesi a 1 anno"
          },
          {
            value: 3,
            label: "1-2 anni"
          },
          {
            value: 4,
            label: "3-5 anni"
          },
          {
            value: 5,
            label: "più di 5 anni"
          }
        ]
      },
      {
        key: "PSICH_diagncomorb",
        label: " Il paziente ha una diagnosi psichiatrica in comorbidità?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PSICH_6_diagnosiprincipale_comorb_quale",
        label: " Specificare la diagnosi principale in comorbidità",
        if: [
          {
            key: "PSICH_diagncomorb",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_6__diagnosi_comorb_quale",
        label: " Altra diagnosi (segnare fino a due diagnosi):",
        if: [
          {
            key: "PSICH_diagncomorb",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Abuso/dipendenza da alcool e/o sostanze stupefacenti"
          },
          {
            value: 2,
            label: "Disturbo bipolare"
          },
          {
            value: 3,
            label:
              "Depressione maggiore, distimia ed altri disturbi affettivi unipolari"
          },
          {
            value: 4,
            label: "Disturbi d’ansia, DOC, disturbi somatoformi"
          },
          {
            value: 5,
            label: "Disturbi del comportamento alimentare"
          },
          {
            value: 6,
            label: "Disturbi di personalità (DSM-5)"
          },
          {
            value: 7,
            label: "Altro"
          }
        ],
        maxRisposte: 2
      },
      {
        key: "PSICH_6__diagnosi_comorb_quale2",
        label: " Specificare l'altra diagnosi ",
        if: [
          {
            key: "PSICH_6__diagnosi_comorb_quale",
            comparator: "=",
            value: 7,
            isMulti: true
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_fam",
        label:
          "Qualcuno all'interno della sua famiglia ha mai ricevuto una diagnosi psichiatrica?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PSICH_trattpsico",
        label: " Trattamento psicofarmacologico al giorno della rilevazione ",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Antipsicotici 1a generazione"
          },
          {
            value: 2,
            label: "Antipsicotici 2a generazione"
          },
          {
            value: 3,
            label: "Clozapina"
          },
          {
            value: 4,
            label: "Stabilizzatori dell’umore (qualsiasi, compreso litio)"
          },
          {
            value: 5,
            label: "Antidepressivi (qualsiasi, di 1a e 2a generazione)"
          },
          {
            value: 6,
            label:
              "Benzodiazepine (qualsiasi, anche se usata a scopo ipnoinducente)"
          }
        ]
      },
      {
        key: "PSICH_trattpsico1",
        label:
          'Numero di farmaci prescritti nella classe "antipsicotici 1a generazione"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 1,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_trattpsico2",
        label:
          'Numero di farmaci prescritti nella classe "antipsicotici 2a generazione"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 2,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_trattpsico3",
        label: ' Numero di farmaci prescritti nella classe "Clozapina"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 3,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_trattpsico4",
        label:
          'Numero di farmaci prescritti nella classe "Stabilizzatori dell\'umore"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 4,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_trattpsico5",
        label: 'Numero di farmaci prescritti nella classe "antidepressivi"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 5,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_trattpsico6",
        label: 'Numero di farmaci prescritti nella classe "benzodiazepine"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 6,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_trattpsico7",
        label: "Da quanti mesi il trattamento è stabile?",
        if: null,
        type: "free-text"
      },
      {
        key: "PSICH_trattpsico8",
        label:
          "Stai assumendo o hai assunto per lunghi periodi integratori alimentari? Se sì, quali?",
        if: null,
        type: "free-text"
      },
      {
        key: "PSICH_trattpsico9",
        label:
          "Stai assumendo o hai assunto per lunghi periodi probiotici e/o prebiotici? Se sì, quali?",
        if: null,
        type: "free-text"
      }
    ]
  },
  {
    type: "BIOMARKERS",
    name: "BIOMARKERS",
    codiceDataCompletamento: "DATE_b_t1",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_b_t1",
        label: "Date ",
        type: "date"
      },
      {
        key: "BIOMARKERS_1_t1",
        label: "HbA1c",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_2_t1",
        label: "Glicemia basale",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_3_t1",
        label: "Glicemia dopo 120 minuti",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_7_t1",
        label: "AST",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_8_t1",
        label: "ALT",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_9_t1",
        label: "Gamma GT",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_10_t1",
        label: "Trigliceridi",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_11_t1",
        label: "Colesterolo totale",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_12_t1",
        label: "HDL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_13_t1",
        label: "LDL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_14_t1",
        label: "LP (a)",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_15_t1",
        label: "Apo A1",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_16_t1",
        label: "Apo B",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_17_t1",
        label: "Insulina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_18_t1",
        label: "C Peptide",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_19_t1",
        label: "Zonulina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_20_t1",
        label: "Adiponectina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_21_t1",
        label: "Leptina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_22_t1",
        label: "AB-TPO",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_23_t1",
        label: "AB-TG",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_24_t1",
        label: "TSH",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_25_t1",
        label: "FT4",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_26_t1",
        label: "Vitamina D",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_27_t1",
        label: "Protein glycation derangements ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_28_t1",
        label: "Zinc-alfa2-glycoprotein ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_29_t1",
        label: "Fetuin-A glycoprotein",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_30_t1",
        label: "P-selectin glycoprotein ",
        if: null,
        type: "free-text"
      }
    ]
  },
  {
    type: "PEC",
    name: "PEC",
    codiceDataCompletamento: "DATE_PEC",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_PEC",
        label: "Date",
        type: "date"
      },
      {
        key: "PEC_1",
        label:
          "Misurata la frequenza cardiaca palpando il polso radiale o auscultata all'apice del cuore per almeno 30 secondi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Frequenza normale del polso: 60-100 battiti al minuto (bpm)"
          },
          {
            value: 2,
            label: "Tachicardia: frequenza > 100 bpm"
          },
          {
            value: 3,
            label: "Bradicardia: frequenza < 60 bpm"
          },
          {
            value: 4,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_1a",
        label: "Anomalie del ritmo del polso",
        if: [
          {
            key: "PEC_1",
            comparator: "=",
            value: 2
          },
          {
            key: "PEC_1",
            comparator: "=",
            value: 3
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Ritmo regolare: intervalli di tempo coerenti tra i battiti"
          },
          {
            value: 2,
            label:
              "Ritmo irregolare: intervalli di tempo incoerenti tra i battiti"
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_1b",
        label: "Qualità del polso",
        if: [
          {
            key: "PEC_1",
            comparator: "=",
            value: 2
          },
          {
            key: "PEC_1",
            comparator: "=",
            value: 3
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Polso forte: facilmente palpabile, indica una buona gittata cardiaca"
          },
          {
            value: 2,
            label:
              "Polso debole/precoce: difficile da sentire, può indicare una scarsa gittata cardiaca"
          },
          {
            value: 3,
            label:
              "Polso irregolare: molto forte, può indicare ipertensione arteriosa o ipertiroidismo"
          },
          {
            value: 4,
            label: "Tensione del polso: polso molle"
          },
          {
            value: 5,
            label: "Tensione del polso: polso duro"
          },
          {
            value: 6,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_2",
        label: "Misurazione iniziale della pressione arteriosa in un braccio",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Pressione arteriosa normale: sistolica: <120 mmHg, diastolica: <80 mmHg"
          },
          {
            value: 2,
            label:
              "Ipertensione stadio 1: sistolica: 130-139 mmHg, diastolica: 80-89 mmHg"
          },
          {
            value: 3,
            label:
              "Ipertensione stadio 2: sistolica: ≥140 mmHg, diastolica: ≥90 mmHg"
          },
          {
            value: 4,
            label: "Ipotensione: sistolica: <90 mmHg, diastolica: <60 mmHg"
          },
          {
            value: 5,
            label:
              "Pre-ipertensione: sistolica: 120-129 mmHg, diastolica: <80 mmHg"
          },
          {
            value: 6,
            label:
              "Pressione arteriosa irregolare: fluttuazioni significative nelle misurazioni durante il\nprocesso a 2 fasi che possono indicare aritmia o ipertensione labile"
          },
          {
            value: 7,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_3",
        label: "Misurazione ripetuta della pressione arteriosa in un braccio",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Pressioni sistoliche e diastoliche coerenti: La seconda misurazione è entro 5 mmHg dalla\nprima misurazione sia per la sistolica che per la diastolica."
          },
          {
            value: 2,
            label:
              "Seconda misurazione sistolica superiore di oltre 5 mmHg rispetto alla prima."
          },
          {
            value: 3,
            label:
              "Seconda misurazione diastolica superiore di oltre 5 mmHg rispetto alla prima."
          },
          {
            value: 4,
            label: "Entrambe le letture sono significativamente più alte"
          },
          {
            value: 5,
            label:
              "Seconda misurazione sistolica inferiore di oltre 5 mmHg rispetto alla prima."
          },
          {
            value: 6,
            label:
              "seconda misurazione diastolica inferiore di oltre 5 mmHg rispetto alla prima."
          },
          {
            value: 7,
            label: "Entrambe le letture sono significativamente più basse."
          },
          {
            value: 8,
            label:
              "Fluttuazione significativa delle letture sistoliche tra le misurazioni."
          },
          {
            value: 9,
            label:
              "Fluttuazione significativa delle letture diastoliche tra le misurazioni."
          },
          {
            value: 10,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_4",
        label: "Misurata la frequenza respiratoria per almeno 30 secondi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Frequenza respiratoria normale: 12-20 respiri al minuto (BPM)."
          },
          {
            value: 2,
            label: "Tachipnea: frequenza respiratoria > 20 BPM."
          },
          {
            value: 3,
            label: "Bradipnea: frequenza respiratoria < 12 BPM."
          },
          {
            value: 4,
            label: "Apnea: assenza di respirazione per più di 10 secondi."
          },
          {
            value: 5,
            label: "Dispnea: respirazione difficoltosa o affannosa."
          },
          {
            value: 6,
            label: "Iperventilazione: respirazione rapida e profonda"
          },
          {
            value: 7,
            label: "Ipoventilazione: respirazione lenta e superficiale."
          },
          {
            value: 8,
            label:
              "Respirazione di Cheyne-Stokes: alternanza di periodi di respirazione profonda e\nsuperficiale con periodi di apnea"
          },
          {
            value: 9,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_5",
        label:
          "Palpate le carotidi bilateralmente al livello della cartilagine tiroidea",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Polso normale: forte e uguale bilateralmente"
          },
          {
            value: 2,
            label:
              "Soffio: suono udibile con uno stetoscopio, che indica un flusso sanguigno turbolento\nspesso dovuto all'aterosclerosi."
          },
          {
            value: 3,
            label:
              "Fremito: vibrazione palpabile che indica un flusso sanguigno turbolento."
          },
          {
            value: 4,
            label:
              "Battito ascendente ritardato: impulso che sale lentamente (stenosi aortica)."
          },
          {
            value: 5,
            label:
              "Asimmetria: differenza nell'intensità del polso tra le arterie carotidee destra e sinistra\n(malattia arteriosa unilaterale)."
          },
          {
            value: 6,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_6",
        label:
          "Auscultate le carotidi bilateralmente con campana, con il paziente in apnea",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Assenza di ‘soffio’: assenza di suoni anormali, che indicano un flusso sanguigno regolare."
          },
          {
            value: 2,
            label:
              "Presenza di ‘soffi’: suono udibile di fruscio o sibilo, che indica un flusso sanguigno\nturbolento dovuto a un'ostruzione parziale o a un restringimento (aterosclerosi)."
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_6a",
        label: "Indicare tutto quello che si applica ",
        if: [
          {
            key: "PEC_6",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Soffio acuto: suggerisce una stenosi di grado elevato."
          },
          {
            value: 2,
            label:
              "Soffio di bassa intensità: può indicare una stenosi meno grave o altre anomalie vascolari"
          },
          {
            value: 3,
            label:
              "Soffio unilaterale: udito da un solo lato (possibile stenosi o ostruzione localizzata sul lato)."
          },
          {
            value: 4,
            label:
              "Soffio bilaterale: sentito su entrambi i lati (possibile malattia arteriosa bilaterale"
          },
          {
            value: 5,
            label: "Soffio sistolico: udito durante la sistole."
          },
          {
            value: 6,
            label:
              "Soffio diastolico: udito durante la diastole, meno comune, può indicare una malattia\ngrave"
          },
          {
            value: 7,
            label:
              "Soffio continuo: sentito durante l’intero ciclo cardiaco (problemi vascolari gravi)."
          },
          {
            value: 8,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_7",
        label: "Ispezionata la parete toracica per forma e simmetria",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Parete toracica simmetrica e di forma normale, entrambi i lati del torace si muovono allo\nstesso modo durante la respirazione."
          },
          {
            value: 2,
            label:
              "Petto a botte: torace arrotondato e sporgente (broncopneumopatia cronica ostruttiva\nBPCO o enfisema)"
          },
          {
            value: 3,
            label:
              'Pectus Excavatum: torace infossato o concavo, definito "torace a imbuto"'
          },
          {
            value: 4,
            label:
              'Pectus Carinatum (petto carenato): torace sporgente o convesso, definito "torace da\npiccione"'
          },
          {
            value: 5,
            label:
              "Cifosi: curvatura anomala della colonna vertebrale della parte superiore della schiena\n(postura arrotondata o ingobbita)."
          },
          {
            value: 6,
            label:
              "Scoliosi: curvatura laterale della colonna vertebrale che provoca un'asimmetria della\nparete toracica"
          },
          {
            value: 7,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_7a",
        label: "Reperti anormali della simmetria del torace",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Movimento asimmetrico: un lato del torace si muove meno dell'altro (possibile\npneumotorace, versamento pleurico o atelettasia)."
          },
          {
            value: 2,
            label:
              "Retrazioni: movimento verso l'interno della parete toracica durante l'inspirazione\n(distress respiratorio o ostruzione delle vie aeree)."
          },
          {
            value: 3,
            label: "Nessuna anomalia"
          },
          {
            value: 4,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_8",
        label: "Esame obiettivo del torace",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Murmure vescicolare: presente e simmetrico su entrambi i lati del torace, senza rumori\naggiunti (come rantoli, sibili o ronchi)"
          },
          {
            value: 2,
            label: "Sibilo: suono acuto (ostruzione delle vie aeree)."
          },
          {
            value: 3,
            label:
              "Stridore: suono aspro e grattugiato (ostruzione delle vie aeree superiori)."
          },
          {
            value: 4,
            label:
              "Crepitii: suoni schioccanti (presenza di liquido nelle vie aeree)."
          },
          {
            value: 5,
            label:
              "Ronchi: suoni bassi (presenza di muco o altre secrezioni nelle vie aeree)."
          },
          {
            value: 6,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_9",
        label:
          " Percussione bilaterale dei campi polmonari posteriori in almeno tre aree",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Suono risonante: suono normale e vuoto (tessuto polmonare sano e pieno d'aria)."
          },
          {
            value: 2,
            label:
              "Suono ottuso: suono simile a un tonfo (presenza di fluidi o tessuti solidi sottostanti, come\nin caso di versamento pleurico, di consolidamento del parenchima polmonare, come nel\ncaso della polmonite, o di massa tumorale)."
          },
          {
            value: 3,
            label:
              "Suono iper-risonante: suono più forte e più basso che indica un aumento dell'aria\n(pneumotorace, enfisema)."
          },
          {
            value: 4,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_9a",
        label: "Specificare ",
        if: [
          {
            key: "PEC_9",
            comparator: "=",
            value: 2
          },
          {
            key: "PEC_9",
            comparator: "=",
            value: 3
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Suono ottuso localizzato in un'area specifica (anormalia localizzata."
          },
          {
            value: 2,
            label:
              "Attenuazione diffusa: suono ottuso diffuso (malattia più estesa o accumulo di liquidi)."
          },
          {
            value: 3,
            label:
              "Iper-risonanza localizzata: suono iper-risonante in un'area specifica (intrappolamento\nd'aria localizzato)."
          },
          {
            value: 4,
            label:
              "Iper-risonanza diffusa: iper-risonanza diffusa (intrappolamento generalizzato dell'aria\ncome in BPCO)."
          },
          {
            value: 5,
            label:
              "Risonanza assente o ridotta: risonanza significativamente ridotta (grave patologia di base,\ncome ampio versamento pleurico o consolidamento polmonare massivo)."
          },
          {
            value: 6,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_10",
        label:
          "Auscultazione dei campi polmonari anteriori bilateralmente in almeno tre aree",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Respiro vescicolare: suoni morbidi e bassi che si sentono nella maggior parte dei campi\npolmonari, con un'inspirazione più lunga dell'espirazione."
          },
          {
            value: 2,
            label:
              "Respiro bronchiale: suoni forti e acuti emessi dalla trachea e dai bronchi maggiori, con\nun'espirazione pari o superiore all'inspirazione"
          },
          {
            value: 3,
            label:
              "Suoni respiratori attenuati: riduzione dell'intensità dei suoni respiratori (scarsa entrata\ndell'aria dovuta a enfisema, versamento pleurico o pneumotorace)."
          },
          {
            value: 4,
            label:
              "Suoni respiratori assenti: assenza di suoni respiratori (patologia grave come\npneumotorace di grandi dimensioni, versamento pleurico massivo o atelettasia)."
          },
          {
            value: 5,
            label:
              "Crepitii fini: suoni acuti e discontinui, solitamente in inspirazione (fibrosi polmonare o\ninsufficienza cardiaca precoce)."
          },
          {
            value: 6,
            label:
              "Crepitii grossolani: suoni bassi e gorgoglianti, in inspirazione (bronchite, polmonite o\nedema polmonare grave)."
          },
          {
            value: 7,
            label:
              "Rantoli: suoni musicali continui e acuti, solitamente in espirazione (restringimento delle\nvie aeree: asma, BPCO o bronchite)."
          },
          {
            value: 8,
            label:
              "Roncopatia: suoni bassi e continui di russamento, generalmente in espirazione (presenza\ndi muco o altre secrezioni nelle grandi vie aeree)"
          },
          {
            value: 9,
            label:
              "Stridore: suono acuto e stridente in inspirazione (ostruzione delle vie aeree superiori)."
          },
          {
            value: 10,
            label:
              "Sfregamento pleurico: suono basso e grattugiato sia in inspirazione che in espirazione\n(infiammazione pleurica)."
          },
          {
            value: 11,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_11",
        label:
          "Auscultazione dei campi polmonari posteriori bilateralmente in almeno tre aree",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Respiro vescicolare: suoni morbidi e bassi che si sentono nella maggior parte dei campi\npolmonari, con un'inspirazione più lunga dell'espirazione."
          },
          {
            value: 2,
            label:
              "Respiro bronchiale: suoni forti e acuti emessi dalla trachea e dai bronchi maggiori, con\nun'espirazione pari o superiore all'inspirazione"
          },
          {
            value: 3,
            label:
              "Suoni respiratori attenuati: riduzione dell'intensità dei suoni respiratori (scarsa entrata\ndell'aria dovuta a enfisema, versamento pleurico o pneumotorace)."
          },
          {
            value: 4,
            label:
              "Suoni respiratori assenti: assenza di suoni respiratori (patologia grave come\npneumotorace di grandi dimensioni, versamento pleurico massivo o atelettasia)."
          },
          {
            value: 5,
            label:
              "Crepitii fini: suoni acuti e discontinui, solitamente in inspirazione (fibrosi polmonare o\ninsufficienza cardiaca precoce)."
          },
          {
            value: 6,
            label:
              "Crepitii grossolani: suoni bassi e gorgoglianti, in inspirazione (bronchite, polmonite o\nedema polmonare grave)."
          },
          {
            value: 7,
            label:
              "Rantoli: suoni musicali continui e acuti, solitamente in espirazione (restringimento delle\nvie aeree: asma, BPCO o bronchite)."
          },
          {
            value: 8,
            label:
              "Roncopatia: suoni bassi e continui di russamento, generalmente in espirazione (presenza\ndi muco o altre secrezioni nelle grandi vie aeree)"
          },
          {
            value: 9,
            label:
              "Stridore: suono acuto e stridente in inspirazione (ostruzione delle vie aeree superiori)."
          },
          {
            value: 10,
            label:
              "Sfregamento pleurico: suono basso e grattugiato sia in inspirazione che in espirazione\n(infiammazione pleurica)."
          },
          {
            value: 11,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_12",
        label: "Auscultato il cuore in posizione eretta e seduta",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Suoni cardiaci normali: S1 e S2 chiari, frequenza e ritmo regolari."
          },
          {
            value: 2,
            label:
              "Mormorii: indicano un flusso sanguigno turbolento attraverso le valvole cardiache."
          },
          {
            value: 3,
            label: "Galoppo: suoni cardiaci extra (S3 o S4)."
          },
          {
            value: 4,
            label:
              "Clic: suoni acuti, che spesso indicano il prolasso della valvola mitrale o la presenza di una\nvalvola protesica."
          },
          {
            value: 5,
            label: "Sfregamenti: Suoni di attrito che indicano pericardite."
          },
          {
            value: 6,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_13",
        label:
          "Palpazione delle gambe per eventuale presenza di edema con pressione moderata per 5 secondi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Assenza di edema (la cute e i tessuti sottostanti ritornano nella loro posizione normale\nimmediatamente dopo il rilascio della pressione, nessuna fovea)."
          },
          {
            value: 2,
            label:
              "Edema lieve (1+) (dopo il rilascio della pressione rimane una leggera rientranza (2 mm),\nma la fovea scompare rapidamente)."
          },
          {
            value: 3,
            label:
              "Edema moderato (2+) (fovea più profonda (4 mm) rimane per 10-15 secondi dopo il\nrilascio della pressione ed è facilmente distinguibile)."
          },
          {
            value: 4,
            label:
              "Edema moderatamente grave (3+) (marcata rientranza (6 mm) che rimane per più di 1\nminuto dopo il rilascio della pressione con area visibilmente gonfia)."
          },
          {
            value: 5,
            label:
              "Edema grave (4+) (rientranza molto profonda (8 mm) che rimane per 2-5 minuti dopo\naver rilasciato la pressione, e gamba molto gonfia e distorta)."
          },
          {
            value: 6,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_15",
        label: "Palpazione bilaterale dei polsi radiali ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Normali bilateralmente."
          },
          {
            value: 2,
            label: "Assenti bilateralmente."
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_16",
        label: "Palpazione bilaterale dei polsi brachiali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Normali bilateralmente."
          },
          {
            value: 2,
            label: "Assenti bilateralmente."
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_17",
        label: "Palpazione bilaterale dei polsi poplitei",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Normali bilateralmente."
          },
          {
            value: 2,
            label: "Assenti bilateralmente."
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_18",
        label: "Palpazione bilaterale dei polsi femorali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Normali bilateralmente."
          },
          {
            value: 2,
            label: "Assenti bilateralmente."
          },
          {
            value: 3,
            label: "Soffio femorale"
          },
          {
            value: 4,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_19",
        label: "Palpazione bilaterale dei polsi pedidi dorsali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Normali bilateralmente."
          },
          {
            value: 2,
            label:
              "Segni di arteriopatia obliterante periferica (pallore cutaneo, alterazione della\ntemperatura, perdita di annessi cutanei, presenza di ulcere distali)."
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_20",
        label: "Vasi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_21",
        label: "Refill capillare",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: ">2 sec"
          },
          {
            value: 2,
            label: "< 2 sec"
          },
          {
            value: 3,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_22",
        label: "Esame obiettivo dell'addome, pt.1",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label:
              "Ispezione:Normale: addome piatto, simmetrico, senza cicatrici o lesioni."
          },
          {
            value: 2,
            label:
              "Ispezione: Anomalo: distensione addominale, asimmetria, cicatrici visibili, eruzioni cutanee."
          },
          {
            value: 3,
            label:
              "Auscultazione: Suoni intestinali normali: presenti e attivi."
          },
          {
            value: 4,
            label:
              "Auscultazione: Anomalo: assenti, ipoattivi, iperattivi, suoni metallici."
          },
          {
            value: 5,
            label:
              "Percussione: Normale: suono timpanico su gran parte dell'addome."
          },
          {
            value: 6,
            label:
              "Percussione: Anomalo: ottusità in aree dove non dovrebbe essere presente (es liquido o massa)."
          },
          {
            value: 7,
            label:
              "Palpazione superficiale: Normale: assenza di dolore alla palpazione leggera."
          },
          {
            value: 8,
            label:
              "Palpazione superficiale:Anomalo: dolore alla palpazione leggera, presenza di masse superficiali."
          },
          {
            value: 9,
            label:
              "Palpazione profonda:Normale: nessuna massa palpabile, organi non ingrossati."
          },
          {
            value: 10,
            label:
              "Palpazione profonda:Anomalo: presenza di masse, organi (fegato, milza) ingrossati."
          },
          {
            value: 11,
            label: "Non valutabile"
          }
        ]
      },
      {
        key: "PEC_23",
        label: "Esame obiettivo dell'addome, pt.2",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label:
              "Palpazione del fegato (margine epatico): Normale: non palpabile o palpabile alla giunzione del bordo costale."
          },
          {
            value: 2,
            label:
              "Palpazione del fegato (margine epatico): Anomalo: palpabile al di sotto del bordo costale, consistenza dura o nodulare."
          },
          {
            value: 3,
            label: "Palpazione della milza: normale, non palplabile"
          },
          {
            value: 4,
            label:
              "Palpazione della milza:Anomalo: palpabile sotto il margine costale sinistro."
          },
          {
            value: 5,
            label: "Segno di Blumberg (rebound tenderness): normale"
          },
          {
            value: 6,
            label:
              "Segno di Blumberg (rebound tenderness): Anomalo: dolore alla rimozione rapida della pressione (possibile peritonite)."
          },
          {
            value: 7,
            label:
              "Segno di Murphy (dolore alla palpazione del quadrante superiore destro durante\nl'inspirazione profonda): normale, assenza di dolore"
          },
          {
            value: 8,
            label:
              "Segno di Murphy (dolore alla palpazione del quadrante superiore destro durante\nl'inspirazione profonda):  Anomalo: dolore acuto che interrompe l'inspirazione (possibile colecistite)"
          },
          {
            value: 9,
            label: "Non valutabile"
          }
        ]
      }
    ]
  },
  {
    type: "TRATTAMENTO FARMACOLOGICO",
    name: "TREATMENT HISTORY",
    codiceDataCompletamento: "DATE_TH",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_TH",
        label: "Date ",
        type: "date"
      },
      {
        key: "TH_23",
        label: "Terapia assunta al momento del colloquio",
        if: null,
        type: "free-text"
      },
      {
        key: "TH_24",
        label: "Ha mai assunto Aripripazolo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_24a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25",
        label: "Ha mai assunto Asenapina?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_25a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26",
        label: "Ha mai assunto Brexpiprazolo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_26a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27",
        label: "Ha mai assunto Cariprazina?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_27a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28",
        label: "Ha mai assunto Clozapina?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_28a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29",
        label: "Ha mai assunto Iloperidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_29a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30",
        label: "Ha mai assunto Lumateperone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_30a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31",
        label: "Ha mai assunto Lurasidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_31a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32",
        label: "Ha mai assunto Olanzapina?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_32a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33",
        label: "Ha mai assunto Paliperidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_33a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34",
        label: "Ha mai assunto Pimavanserin?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_34a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35",
        label: "Ha mai assunto Quetiapina?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_35a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_36",
        label: "Ha mai assunto Risperidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_36a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_36",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_36",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_36b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_36",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_36",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_36c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_36",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_36",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_36d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_36",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_36",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_37",
        label: "Ha mai assunto Ziprasidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_37a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_37",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_37",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_37b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_37",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_37",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_37c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_37",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_37",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_37d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_37",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_37",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_38",
        label: "Ha mai assunto Melatonina?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Mai assunto"
          },
          {
            value: 2,
            label: "Lo sto assumendo ora"
          },
          {
            value: 3,
            label: "Assunto in passato"
          }
        ]
      },
      {
        key: "TH_38a",
        label: "Per quanto tempo lo ha assunto?",
        if: [
          {
            key: "TH_38",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_38",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_38b",
        label: "Come si trovava con queste farmaco?",
        if: [
          {
            key: "TH_38",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_38",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_38c",
        label: "Ha mai avuto effetti indesiderati? Se sì, quali?",
        if: [
          {
            key: "TH_38",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_38",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_38d",
        label: "Quale è stata la dose più alta che ha assunto?",
        if: [
          {
            key: "TH_38",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_38",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "BPRS",
    name: "BRIEF PSYCHIATRIC RATING SCALE (BPRS)",
    codiceDataCompletamento: "DATA_04",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_04",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "BPRS_1",
        label: "1) PREOCCUPAZIONI SOMATICHE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_2",
        label: "2) ANSIA",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_3",
        label: "3) DEPRESSIONE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_4",
        label: "4) RISCHIO DI SUICIDIO",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_5",
        label: "5) SENTIMENTI DI COLPA",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_6",
        label: "6) OSTILITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_7",
        label: "7) ELEVAZIONE DEL TONO DELL’UMORE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_8",
        label: "8) GRANDIOSITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_9",
        label: "9) SOSPETTOSITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_10",
        label: "10) ALLUCINAZIONI ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_11",
        label: "11) CONTENUTO INSOLITO DEL PENSIERO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_12",
        label: "12) COMPORTAMENTO BIZZARRO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_13",
        label: "13) TRASCURATEZZA DELLA CURA DI SÉ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_14",
        label: "14) DISORIENTAMENTO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_15",
        label: "15) DISORGANIZZAZIONE CONCETTUALE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_16",
        label: "16) APPIATTIMENTO AFFETTIVO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_17",
        label: "17) ISOLAMENTO EMOTIVO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_18",
        label: "18) RALLENTAMENTO MOTORIO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_19",
        label: "19) TENSIONE MOTORIA ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_20",
        label: "20) MANCANZA DI COOPERAZIONE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_21",
        label: "21) ECCITAMENTO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_22",
        label: "22) DISTRAIBILITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_23",
        label: "23) IPERATTIVITÀ MOTORIA ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_24",
        label: "24) MANIERISMI E POSTURE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      }
    ]
  },
  {
    type: "WHODAS 2.0",
    name: "WHO DISABILITY ASSESSMENT SCHEDULE (WHODAS) ",
    codiceDataCompletamento: "DATA_11",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_11",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WHODAS_0",
        label:
          "Questo questionario esplora le difficoltà che sono in relazione a problemi di salute. Per problemi di salute si intendono malattie o altri disturbi che possono essere di breve o di lunga durata, traumi, problemi mentali o emotivi e problemi con alcol o droghe.\nRipensi agli ultimi 30 giorni e risponda alle domande pensando a quanta difficoltà ha avuto nello svolgere le attività di seguito elencate.\nPer ogni domanda, deve indicare un solo numero per ciascuna voce.",
        if: null,
        type: "description"
      },
      {
        key: "WHODAS_1",
        label: "S1) Stare in piedi per un lungo periodo, come per 30 minuti? ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_2",
        label:
          "S2) Prendersi cura della casa e della famiglia per quanto\nè di sua responsabilità?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_3",
        label:
          "S3) Imparare cose nuove, come, per esempio, imparare a raggiungere un posto nuovo?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_4",
        label:
          "S4) Quanti problemi ha avuto nel partecipare ad attività comunitarie (per esempio, feste, attività religiose o di altro tipo) come chiunque altro?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_5",
        label:
          "S5) Quanto è stato coinvolto emotivamente dal suo problema di salute?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_6",
        label: "S6) Concentrarsi nel fare qualcosa per dieci minuti?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_7",
        label: "S7) Camminare per una lunga distanza, come per un chilometro?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_8",
        label: "S8) Lavarsi tutto il corpo?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_9",
        label: "S9) Vestirsi?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_10",
        label: "S10)  Interagire con persone che non conosce?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_11",
        label: "S11) Mantenere un’amicizia?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_12",
        label: "S12) Svolgere l’attività lavorativa/scolastica quotidiana?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_13",
        label:
          "H1) Complessivamente, negli ultimi 30 giorni, per quanti giorni ha avuto queste difficoltà?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_14",
        label:
          "H2) Negli ultimi 30 giorni, per quanti giorni è stato impossibilitato a svolgere le attività o il lavoro abituali a causa delle sue condizioni di salute?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_15",
        label:
          "H3) Negli ultimi 30 giorni, senza contare i giorni in cui è stato impossibilitato, per quanti giorni ha diminuito o ridotto le attività o il lavoro abituale a causa delle sue condizioni di salute?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_16",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "WHODAS_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ],
          divideBy: 12
        }
      }
    ]
  },
  {
    type: "SLOF",
    name: "SPECIFIC LEVELS OF FUNCTIONING SCALE (SLOF) ",
    codiceDataCompletamento: "DATA_06",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_06",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "SLOF_0",
        label:
          "Verifichi quale numero meglio descrive il caratteristico livello di funzionamento del soggetto per ogni voce elencata sotto. Sia il più accurato possibile. Se non è sicuro rispetto ad un determinato punteggio, chieda a qualcuno che conosce il paziente o consulti la cartella clinica. Segni un solo numero per ogni voce. Controlli di aver contrassegnato tutte le voci. ",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_a",
        label: "CURA DI Sé - CONDIZIONE FISICA",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_1",
        label: "1) Vista",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_2",
        label: "2) Udito",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_3",
        label: "3) Compromissione dell'eloquio",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_4",
        label: "4) Deambulazione, uso delle gambe",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_5",
        label: "5) Utilizzo di mani e braccia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_b",
        label: "CURA DI Sé - COMPETENZE NELLA CURA DI Sé",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_6",
        label:
          "6) Andare alla toilette (usa correttamente la toilette, mantiene puliti sé e lo spazio) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_7",
        label:
          "7) Alimentazione (utilizza gli utensili correttamente, abitudini alimentari) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_8",
        label: "8) Igiene personale (corpo e denti, pulizia generale) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_9",
        label:
          "9) Vestirsi da soli (seleziona capi di abbigliamento adeguatamente; si veste autonomamente) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_10",
        label:
          "10) Cura della propria persona (capelli, trucco, aspetto generale) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_11",
        label: "11) Cura dei propri beni",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_12",
        label: "12) Cura del proprio spazio vitale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_c",
        label: "FUNZIONAMENTO SOCIALE – RELAZIONI INTERPERSONALI",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_13",
        label:
          "13) Tollera i contatti con gli altri (non si allontana o respinge) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_14",
        label: "14) Stabilisce i contatti con gli altri",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_15",
        label:
          "15) Comunica in modo efficace (discorso e gestualità comprensibili e attinenti) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_16",
        label: "16) Partecipa alle attività senza suggerimenti ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_17",
        label: "17) Partecipa a gruppi ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_18",
        label: "18) Allaccia e mantiene le amicizie ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_19",
        label: "19) Chiede aiuto quando necessita ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_d",
        label: "FUNZIONAMENTO SOCIALE – ACCETTABILITÀ SOCIALE",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_20",
        label: "20) Abusi verbali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_21",
        label: "21) Abusi fisici",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_22",
        label: "22) Distrugge beni",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_23",
        label: "23) E' aggressivo fisicamente verso se stesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_24",
        label: "24) Ha paura, piange, è appiccicoso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_25",
        label: "25) Si appropria di beni altrui senza autorizzazione",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_26",
        label:
          "26) Reitera i comportamenti (passi, oscillazioni, rumori, ecc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_e",
        label: "COMPETENZE IN AMBITO COMUNITARIO - ATTIVITà",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_27",
        label:
          "27) Responsabilità domestiche (pulizia della casa, cucinare, lavare vestiti, ecc.) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_28",
        label:
          "28) Acquisti (selezione di articoli, scelta di negozi, pagamento di cassa)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_29",
        label:
          "29) Gestione delle proprie finanze (gestione del budget, pagamento delle bollette)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_30",
        label:
          "30) Uso del telefono (trovare il numero, digitare il numero, conversazione, ascolto)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_31",
        label: "31) Allontanamento dalla propria abitazione senza perdersi ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_32",
        label:
          "32) Utilizzo dei trasporti pubblici (selezionare percorso, usare gli orari, pagare tariffe, effettuare i trasferimenti) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_33",
        label:
          "33) Impiego del tempo libero (letture, visite agli amici, ascoltare musica, ecc.) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_34",
        label:
          "34) Riconoscere ed evitare pericoli comuni (traffico, incendio, ecc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_35",
        label:
          "35) Automedicazione (comprendere lo scopo, assumere come prescritto, riconoscere gli effetti collaterali) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_36",
        label:
          "36) Utilizzo dei revizi medici e di comunità (sapere a chi rivolgersi, come e quando usarli) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_37",
        label:
          "37) Lettura di base, scrittura e calcolo (sufficiente per le necessità quotidiane) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_f",
        label: "COMPETENZE IN AMBITO COMUNITARIO - CAPACITà LAVORATIVE",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_38",
        label: "38) Possiede competenze lavorative",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_39",
        label: "39) Lavora con una supervisione minima",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_40",
        label:
          "40) Sostiene gli sforzi lavorativi (non si distrae facilmente, è capace di lavorare sotto stress) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_41",
        label: "41) Si presenta agli appuntamenti puntuale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_42",
        label: "42) Segue accuratamente le istruzioni verbali ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_43",
        label: "43) Completa i compiti assegnati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_g",
        label: "ALTRE INFORMAZIONI",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_44",
        label:
          "44) In base alla conoscenza di questa persona, ci sono altre abilità o aree problematiche non contemplate da questo questionario e rilevanti ai fini della capacità di questa persona di operare in modo indipendente? Se è così, si prega di specificare. ",
        if: null,
        type: "free-text"
      },
      {
        key: "SLOF_45",
        label:
          "45) Quanto conosce le abilità e il comportamento della persona che ha appena valutato?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 5,
            label: "5. Molto bene"
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 1,
            label: "1. Per nulla"
          }
        ]
      },
      {
        key: "SLOF_46",
        label: "46) Ha discusso questa valutazione con il soggetto?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 4,
            label: "No"
          },
          {
            value: 5,
            label: "Sì"
          }
        ]
      },
      {
        key: "SLOF_46a",
        label:
          "46.a) Se SÌ, l’individuo concorda generalmente con la valutazione? ",
        if: [
          {
            key: "SLOF_46",
            comparator: "=",
            value: 5
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 4,
            label: "No"
          },
          {
            value: 5,
            label: "Sì"
          }
        ]
      },
      {
        key: "SLOF_46b",
        label: "46.b) Se NO, si prega di commentare ",
        if: [
          {
            key: "SLOF_46",
            comparator: "=",
            value: 4
          }
        ],
        type: "free-text"
      },
      {
        key: "SLOF_47",
        label: "PUNTEGGIO CURA DI SE'",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SLOF_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_2",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_3",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_6",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_12",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "SLOF_48",
        label: "PUNTEGGIO FUNZIONAMENTO SOCIALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SLOF_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_14",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_15",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_16",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_17",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_18",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_19",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_20",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_21",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_22",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_25",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_26",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "SLOF_49",
        label: "PUNTEGGIO ABILITA' SOCIALI",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SLOF_27",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_28",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_29",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_30",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_31",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_32",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_33",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_34",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "ECI",
    name: "Elixahuser Comorbidity Index",
    codiceDataCompletamento: "DATA_Elixahuser",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_Elixahuser",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "Elixahuser_0",
        label:
          "Qui sotto troverà un elenco di disturbi fisici. Per ogni disturbo vi è uno specifico punteggio da assegnare. Si prega di segnare con una crocetta i disturbi di cui ha sofferto il partecipante nel corso della sua vita. Il punteggio totale è calcolato dalla somma dei singoli punteggi in colonna.",
        if: null,
        type: "description"
      },
      {
        key: "Elixahuser_1",
        label: "Congestive heart failure",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_2",
        label: "Cardiac arrhythmias",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_3",
        label: "Valvular disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_4",
        label: "Pulmonary circulation disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_5",
        label: "Peripheral vascular disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_6",
        label: "Hypertension, uncomplicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_7",
        label: "Hypertension, complicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_8",
        label: "Paralysis",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_9",
        label: "Other neurological disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_10",
        label: "Chronic pulmonary disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_11",
        label: "Diabetes, uncomplicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_12",
        label: "Diabetes, complicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_13",
        label: "Hypothyroidism",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_14",
        label: "Renal failure",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_15",
        label: "Liver disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_16",
        label: "Peptic ulcer disease excluding bleeding",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_17",
        label: "AIDS/HIV",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_18",
        label: "Lymphoma",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_19",
        label: "Metastatic cancer",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_20",
        label: "Solid tumor without metastasis",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_21",
        label: "Rheumatoid arthritis/collagen vascular diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_22",
        label: "Coagulopathy",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_23",
        label: "Obesity",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_24",
        label: "Weight loss",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_25",
        label: "Fluid and electrolyte disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_26",
        label: "Blood loss anemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_27",
        label: "Deficiency anemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_28",
        label: "Alcohol abuse",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_29",
        label: "Drug abuse",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_30",
        label: "Psychoses",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_31",
        label: "Depression",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_TOTAL_SCORE",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "Elixahuser_1",
              map: {
                "0": 0,
                "1": 9
              }
            },
            {
              key: "Elixahuser_2",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_3",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_4",
              map: {
                "0": 0,
                "1": 6
              }
            },
            {
              key: "Elixahuser_5",
              map: {
                "0": 0,
                "1": 3
              }
            },
            {
              key: "Elixahuser_6",
              map: {
                "0": 0,
                "1": -1
              },
              onlyIf: {
                key: "Elixahuser_7",
                value: 0,
                elseValue: 0
              }
            },
            {
              key: "Elixahuser_7",
              map: {
                "0": 0,
                "1": -1
              }
            },
            {
              key: "Elixahuser_8",
              map: {
                "0": 0,
                "1": 5
              }
            },
            {
              key: "Elixahuser_9",
              map: {
                "0": 0,
                "1": 5
              }
            },
            {
              key: "Elixahuser_10",
              map: {
                "0": 0,
                "1": 3
              }
            },
            {
              key: "Elixahuser_11",
              map: {
                "0": 0,
                "1": 0
              },
              onlyIf: {
                key: "Elixahuser_12",
                value: 0,
                elseValue: 0
              }
            },
            {
              key: "Elixahuser_12",
              map: {
                "0": 0,
                "1": -3
              }
            },
            {
              key: "Elixahuser_13",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_14",
              map: {
                "0": 0,
                "1": 6
              }
            },
            {
              key: "Elixahuser_15",
              map: {
                "0": 0,
                "1": 4
              }
            },
            {
              key: "Elixahuser_16",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_17",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_18",
              map: {
                "0": 0,
                "1": 6
              }
            },
            {
              key: "Elixahuser_19",
              map: {
                "0": 0,
                "1": 14
              }
            },
            {
              key: "Elixahuser_20",
              map: {
                "0": 0,
                "1": 7
              },
              onlyIf: {
                key: "Elixahuser_19",
                value: 0,
                elseValue: 0
              }
            },
            {
              key: "Elixahuser_21",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_22",
              map: {
                "0": 0,
                "1": 11
              }
            },
            {
              key: "Elixahuser_23",
              map: {
                "0": 0,
                "1": -5
              }
            },
            {
              key: "Elixahuser_24",
              map: {
                "0": 0,
                "1": 9
              }
            },
            {
              key: "Elixahuser_25",
              map: {
                "0": 0,
                "1": 11
              }
            },
            {
              key: "Elixahuser_26",
              map: {
                "0": 0,
                "1": -3
              }
            },
            {
              key: "Elixahuser_27",
              map: {
                "0": 0,
                "1": -2
              }
            },
            {
              key: "Elixahuser_28",
              map: {
                "0": 0,
                "1": -1
              }
            },
            {
              key: "Elixahuser_29",
              map: {
                "0": 0,
                "1": -7
              }
            },
            {
              key: "Elixahuser_30",
              map: {
                "0": 0,
                "1": -5
              }
            },
            {
              key: "Elixahuser_31",
              map: {
                "0": 0,
                "1": -5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "EQ5D",
    name: "EQ5D",
    codiceDataCompletamento: "DATE_09",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATE_09",
        label: "Date",
        type: "date"
      },
      {
        key: "EQ5D_1",
        label: "MOBILITA'",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non ho difficoltà nel camminare= 1"
          },
          {
            value: 2,
            label: "Ho lievi difficoltà nel camminare = 2"
          },
          {
            value: 3,
            label: "Ho moderate difficoltà nel camminare= 3"
          },
          {
            value: 4,
            label: "Ho gravi difficoltà nel camminare=4"
          },
          {
            value: 5,
            label: "Non sono in grado di camminare=5"
          }
        ]
      },
      {
        key: "EQ5D_2",
        label: "CURA DI SE'",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non ho difficoltà nel lavarmi o vestirmi=1"
          },
          {
            value: 2,
            label: "Ho lievi difficoltà nel lavarmi o vestirmi=2"
          },
          {
            value: 3,
            label: "Ho moderate difficoltà nel lavarmi o vestirmi=3"
          },
          {
            value: 4,
            label: "Ho gravi difficoltà nel lavarmi o vestirmi=4"
          },
          {
            value: 5,
            label: "Non sono in grado di lavarmi o vestirmi=5"
          }
        ]
      },
      {
        key: "EQ5D_3",
        label: "ATTIVITA' QUOTIDIANE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Non ho difficoltà nello svolgimento delle attività abituali=1"
          },
          {
            value: 2,
            label:
              "Ho lievi difficoltà nello svolgimento delle attività abituali=2"
          },
          {
            value: 3,
            label:
              "Ho moderate difficoltà nello svolgimento delle attività abituali=3"
          },
          {
            value: 4,
            label:
              "Ho gravi difficoltà nello svolgimento delle attività abituali=4"
          },
          {
            value: 5,
            label: "Non sono in grado di svolgere le mie attività abituali=5"
          }
        ]
      },
      {
        key: "EQ5D_4",
        label: "DOLORE/FASTIODIO",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non provo alcun dolore o fastidio=1"
          },
          {
            value: 2,
            label: "Provo lieve dolore o fastidio=2"
          },
          {
            value: 3,
            label: "Provo moderato dolore o fastidio=3"
          },
          {
            value: 4,
            label: "Provo grave dolore o fastidio=4"
          },
          {
            value: 5,
            label: "Provo estremo dolore o fastidio=5"
          }
        ]
      },
      {
        key: "EQ5D_5",
        label: "ANSIA/DEPRESSIONE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non sono ansioso/a o depresso/a=1"
          },
          {
            value: 2,
            label: "Sono lievemente ansioso/a o depresso/a=2"
          },
          {
            value: 3,
            label: "Sono moderatamente ansioso/a o depresso/a=3"
          },
          {
            value: 4,
            label: "Sono gravemente ansioso/a o depresso/a=4"
          },
          {
            value: 5,
            label: "Sono estremamente ansioso/a o depresso/a=5"
          }
        ]
      },
      {
        key: "EQ5D_6",
        label: "COME VALUTI LA TUA SALUTE OGGI",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 100
        },
        step: "any",
        auto: null
      },
      {
        key: "EQ5D_7",
        label: "PUNTEGGIO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "EQ5D_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_2",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_3",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "PSQI",
    name: "PSQI questionnaire",
    codiceDataCompletamento: "DATA_PSQI",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_PSQI",
        label: "Date",
        type: "date"
      },
      {
        key: "PSQI_0",
        label:
          "Le seguenti domande si riferiscono alle tue abituali abitudini di sonno solo durante l'ultimo mese. Le tue risposte dovrebbero indicare la risposta più accurata per la maggior parte dei giorni e delle notti nell'ultimo mese. Si prega di rispondere a tutte le domande.\n",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_1",
        label:
          "1. Durante l'ultimo mese, a che ora sei andato a letto di solito la sera",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_2",
        label:
          "2.  Durante l'ultimo mese, quanto tempo (in minuti) hai impiegato di solito per addormentarti ogni notte?\n",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_3",
        label:
          "3. Durante l'ultimo mese, a che ora ti sei alzato di solito la mattina?",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_4",
        label:
          "4. Durante il mese scorso, quante ore di sonno effettivo hai avuto di notte? (Questo potrebbe essere diverso dal numero di ore trascorse a letto).",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_5",
        label:
          "5. Durante l'ultimo mese, quante volte hai avuto problemi a dormire perché:",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_6",
        label: "a. Era Impossibile addormentarsi entro 30 minuti",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese= 0"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana = 1"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana=2"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana = 3"
          }
        ]
      },
      {
        key: "PSQI_7",
        label: "b. Si è svegliato nel cuore della notte o al mattino presto",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_8",
        label: "c. Ha dovuto alzarsi per usare il bagno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_9",
        label: "d. Non riusciva a respirare bene",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_10",
        label: "e. Tossiva o russava",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_11",
        label: "f. Sentiva troppo freddo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_12",
        label: "g. Sentiva troppo caldo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_13",
        label: "h. Ha avuto gli incubi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_14",
        label: "i. Provava dolore",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_15",
        label: "j. Altri motivi, descrivere",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_16",
        label:
          "Quante volte nell'ultimo mese hai avuto problemi a dormire a causa di questo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_17",
        label:
          "6.  Durante l'ultimo mese, quante volte hai preso medicine per aiutarti a dormire (prescritte o meno)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_18",
        label:
          "7. Durante l'ultimo mese, quante volte hai avuto problemi a rimanere sveglio mentre guidi, mangi o ti impegni in attività sociali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_19",
        label:
          "8. Durante l'ultimo mese, quanto è stato un problema per te mantenere abbastanza entusiasmo per fare le cose",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Per niente"
          },
          {
            value: 1,
            label: "Solo un piccolo problema"
          },
          {
            value: 2,
            label: "Per certi versi un problema"
          },
          {
            value: 3,
            label: "Un grosso problema"
          }
        ]
      },
      {
        key: "PSQI_20",
        label: "9. Come valuteresti la tua qualità del sonno dell'ultimo mese?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Molto bene"
          },
          {
            value: 1,
            label: "Abbastanza bene"
          },
          {
            value: 2,
            label: "Abbastanza male"
          },
          {
            value: 3,
            label: "Molto male"
          }
        ]
      },
      {
        key: "PSQI_21",
        label: "10. Hai un compagno di letto o un compagno di stanza",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Dormo da solo"
          },
          {
            value: 1,
            label: "Partner/coinquilini in altra stanza"
          },
          {
            value: 2,
            label: "Partner/coinquilini nella stessa stanza ma letto diverso"
          },
          {
            value: 3,
            label: "Partner nello stesso letto"
          }
        ]
      },
      {
        key: "PSQI_22",
        label:
          "Se hai un compagno di stanza o un compagno di letto, chiedigli quante volte nell'ultimo mese hai avuto\n",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_23",
        label: "a. Forte russamento",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_24",
        label: "b. Lunghe pause tra un respiro e l'altro durante il sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_25",
        label: "c. Gambe che si contraggono o si agitano mentre dormi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_26",
        label: "d. Episodi di disorientamento o confusione durante il sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_27",
        label:
          "e. Altra irrequietezza mentre dormi; Si prega di descrivere...\n",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_28",
        label:
          "Quante volte nell'ultimo mese hai provato irrequietezza mentre dormivi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_Durata",
        label: "Durata del sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_ PSQIDISTB",
        label: "Problemi del sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_SLEEP LATENCY",
        label: "Latenza del sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_DYSF",
        label: "Disfunzioni giornaliere legate all'assenza di sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_SE",
        label: "Efficienza del sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_OSP",
        label: "Qualità del sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_MEDS ",
        label: "Necessità di assumere medicine per dormire",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_TOT",
        label: "TOTAL",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "SF-36",
    name: "SF-36 questionnaire",
    codiceDataCompletamento: "DATA_SF-36",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_SF-36",
        label: "Date",
        type: "date"
      },
      {
        key: "SF-36_0",
        label:
          "La preghiamo di rispondere alle 36 domande dell'Indagine sulla salute in modo completo, onesto e senza interruzioni per la maggior parte dei giorni e delle notti dell'ultimo mese. Rispondere a tutte le domandE",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_1",
        label: "In generale, direbbe che la sua salute è",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Eccellente"
          },
          {
            value: 2,
            label: "Molto buona"
          },
          {
            value: 3,
            label: "Buona"
          },
          {
            value: 4,
            label: "Discreta"
          },
          {
            value: 5,
            label: "Povera"
          }
        ]
      },
      {
        key: "SF-36_2",
        label:
          "Rispetto a un anno fa, come giudica il suo stato di salute in generale?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Molto meglio di un anno fa=1"
          },
          {
            value: 2,
            label: "Meglio di un anno fa=2"
          },
          {
            value: 3,
            label: "Lo stesso e=3"
          },
          {
            value: 4,
            label: "In qualche modo peggio dell'anno scorso =4"
          },
          {
            value: 5,
            label: "Molto peggio dell'anno scorso=5"
          }
        ]
      },
      {
        key: "SF-36_3",
        label:
          "LIMITI DELLE ATTIVITÀ: Le seguenti voci riguardano le attività che potreste svolgere durante una giornata tipica. La sua salute la limita in queste attività? Se sì, quanto?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_4",
        label:
          "Attività vigorose, come la corsa, il sollevamento di oggetti pesanti, la partecipazione a sport faticosi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_5",
        label:
          "Attività Moderatamente, come spostare un tavolo, spingere un aspirapolvere, giocare a bowling o giocare a golf.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_6",
        label: "Alzare pesi o portare la spesa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_7",
        label: "Salire più piani di scale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_8",
        label: "Salire una rampa di scale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_9",
        label: "Piegarsi, inginocchiarsi o abbassarsi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_10",
        label: "Camminare per più di un chilometro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_11",
        label: "Camminare per diversi isolati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_12",
        label: "Camminare per un isolato",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_13",
        label: "Fare il bagno o vestirsi da soli",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_14",
        label:
          "PROBLEMI DI SALUTE FISICA:\nNelle ultime 4 settimane, ha avuto uno dei seguenti problemi con il lavoro o con altre attività quotidiane regolari a causa della sua salute fisica?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_15",
        label:
          "Ridurre la quantità di tempo dedicata al lavoro o ad altre attività.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì=1"
          },
          {
            value: 2,
            label: "No=2"
          }
        ]
      },
      {
        key: "SF-36_16",
        label: "Risultati inferiori a quelli desiderati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_17",
        label: "Era limitato nel tipo di lavoro o in altre attività",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_18",
        label:
          "Ha avuto difficoltà a svolgere il lavoro o altre attività (per esempio, ha richiesto uno sforzo supplementare)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_19",
        label:
          "PROBLEMI DI SALUTE EMOTIVA: Nel corso delle ultime 4 settimane, ha avuto uno dei seguenti problemi con il lavoro o con altre attività quotidiane regolari a causa di problemi emotivi (come sentirsi depresso o ansioso)?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_20",
        label:
          "Ridurre la quantità di tempo dedicata al lavoro o ad altre attività.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_21",
        label: "Risultati inferiori a quelli desiderati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_22",
        label:
          "Non ha svolto il lavoro o altre attività con la stessa attenzione di sempre",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_23",
        label:
          "ATTIVITÀ SOCIALI: I problemi emotivi hanno interferito con le sue normali attività sociali con la famiglia, gli amici, i vicini o i gruppi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente=1"
          },
          {
            value: 2,
            label: "Leggermente=2"
          },
          {
            value: 3,
            label: "Moderatamente=3"
          },
          {
            value: 4,
            label: "Grave=4"
          },
          {
            value: 5,
            label: "Molto grave=5"
          }
        ]
      },
      {
        key: "SF-36_24",
        label:
          "DOLORE: Quanto dolore corporeo ha avuto nelle ultime 4 settimane?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Nessuno=1"
          },
          {
            value: 2,
            label: "Molto lieve=2"
          },
          {
            value: 3,
            label: "Lievi=3"
          },
          {
            value: 4,
            label: "Moderatamente=4"
          },
          {
            value: 5,
            label: "Grave=5"
          },
          {
            value: 6,
            label: "Molto grave=6"
          }
        ]
      },
      {
        key: "SF-36_25",
        label:
          "Nelle ultime 4 settimane, quanto il dolore ha interferito con il suo normale lavoro (compreso il lavoro fuori casa e i lavori domestici)?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente=1"
          },
          {
            value: 2,
            label: "Poco=2"
          },
          {
            value: 3,
            label: "Moderatamente=3"
          },
          {
            value: 4,
            label: "Abbastanza=4"
          },
          {
            value: 5,
            label: "Extremely=5"
          }
        ]
      },
      {
        key: "SF-36_26",
        label:
          "ENERGIA ED EMOZIONI:\nQueste domande riguardano come vi sentite e come sono andate le cose nelle ultime 4 settimane. Per ogni domanda, date la risposta che più si avvicina al modo in cui vi siete sentiti.",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_27",
        label: "Si è sentito pieno di energia?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo=1"
          },
          {
            value: 2,
            label: "La maggior parte del tempo=2"
          },
          {
            value: 3,
            label: "Buona parte del tempo=3"
          },
          {
            value: 4,
            label: "Un po' di tempo=4"
          },
          {
            value: 5,
            label: "Poco tempo=5"
          },
          {
            value: 6,
            label: "Per niente=6"
          }
        ]
      },
      {
        key: "SF-36_28",
        label: "È stato una persona molto nervosa?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_29",
        label:
          "Si è sentito così giù di morale che niente riusciva a tirarlo su?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_30",
        label: "Si è sentito calmo e tranquillo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_31",
        label: "Aveva molta energia?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_32",
        label: "Si è sentito giù di morale e triste?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_33",
        label: "Si è sentito esaurito?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_34",
        label: "È stata una persona felice?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_35",
        label: "Si è sentito stanco?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_36",
        label:
          "ATTIVITÀ SOCIALI: Nelle ultime 4 settimane, per quanto tempo la sua salute fisica o i suoi problemi emotivi hanno interferito con le sue attività sociali (come le visite agli amici, ai parenti, ecc.)?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_37",
        label:
          "SALUTE GENERALE: Quanto è vera o falsa per lei ciascuna delle seguenti affermazioni?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_38",
        label: "Sembra che io mi ammali un po' più facilmente di altre persone",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero=1"
          },
          {
            value: 2,
            label: "Abbastanza vero=2"
          },
          {
            value: 3,
            label: "Non lo so=3"
          },
          {
            value: 4,
            label: "Abbastanza falso=4"
          },
          {
            value: 5,
            label: "Assolutamente falso=5"
          }
        ]
      },
      {
        key: "SF-36_39",
        label: "Sono in salute come chiunque altro conosca",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero"
          },
          {
            value: 2,
            label: "Abbastanza vero"
          },
          {
            value: 3,
            label: "Non lo so"
          },
          {
            value: 4,
            label: "Abbastanza falso"
          },
          {
            value: 5,
            label: "Assolutamente falso"
          }
        ]
      },
      {
        key: "SF-36_40",
        label: "Mi aspetto che la mia salute peggiori",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero"
          },
          {
            value: 2,
            label: "Abbastanza vero"
          },
          {
            value: 3,
            label: "Non lo so"
          },
          {
            value: 4,
            label: "Abbastanza falso"
          },
          {
            value: 5,
            label: "Assolutamente falso"
          }
        ]
      },
      {
        key: "SF-36_41",
        label: "La mia salute è eccellente",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero"
          },
          {
            value: 2,
            label: "Abbastanza vero"
          },
          {
            value: 3,
            label: "Non lo so"
          },
          {
            value: 4,
            label: "Abbastanza falso"
          },
          {
            value: 5,
            label: "Assolutamente falso"
          }
        ]
      },
      {
        key: "SF-36_42",
        label: "FUNZIONAMENTO FISICO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_6",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_12",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            }
          ],
          divideBy: 10
        }
      },
      {
        key: "SF-36_43",
        label: "LIMITAZIONI DI RUOLO DOVUTE ALLA SALUTE FISICA",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_15",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_16",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_17",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_18",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_44",
        label: "LIMITAZIONI DI RUOLO DOVUTE ALLA SALUTE EMOTIVA",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_20",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_21",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_22",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 3
        }
      },
      {
        key: "SF-36_45",
        label: "ENERGIA/AFFATICAMENTO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_27",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_31",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_33",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_35",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_46",
        label: "BENESSERE EMOTIVO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_28",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_29",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_30",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_32",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_34",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            }
          ],
          divideBy: 5
        }
      },
      {
        key: "SF-36_47",
        label: "FUNZIONAMENTO SOCIALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_36",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_48",
        label: "DOLORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_25",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_49",
        label: "SALUTE GENERALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_38",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_39",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_40",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_41",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 5
        }
      }
    ]
  },
  {
    type: "WHOQOL-BREF",
    name:
      "WORLD HEALTH ORGANIZATION QUALITY OF LIFE - Versione breve  (WHOQOL-Bref)",
    codiceDataCompletamento: "DATA_12",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_12",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WHOQOL_0",
        label:
          "Questo questionario riguarda quello che Lei pensa della qualità della Sua vita, della Sua salute, e di altri aspetti della Sua vita. Per favore, risponda a tutte le domande:\nse è incerto sulla risposta da fornire ad una domanda, scelga, per favore, quella che corrisponde di più alla sua opinione; spesso si tratterà della risposta che Le è venuta in mente per prima.\nPer favore, nel rispondere tenga conto delle sue abitudini di vita, delle Sue speranze, dei Suoi gusti e delle Sue preoccupazioni e pensi a quello che Le è successo negli ultimi 15 giorni. Lei deve fare un segno sul numero che meglio esprime in che misura Lei ha ricevuto l’aiuto di cui aveva bisogno negli ultimi 15 giorni.\nQuindi, Lei deve fare un segno sul numero 4 se ha ricevuto “Molto” aiuto dagli altri, o sul numero 1 se non ha ricevuto “Per niente” l’aiuto di cui aveva bisogno.\nLegga attentamente ogni domanda, rifletta sui Suoi stati d’animo e, come Le abbiamo già suggerito, faccia un segno sulla risposta che meglio corrisponde alla sua opinione o ai sui sentimenti.",
        if: null,
        type: "description"
      },
      {
        key: "WHOQOL_1",
        label: "1) Come valuta la qualità della sua vita?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto cattiva"
          },
          {
            value: 2,
            label: "2= Cattiva"
          },
          {
            value: 3,
            label: "3= Nè cattiva nè buona"
          },
          {
            value: 4,
            label: "4= Buona"
          },
          {
            value: 5,
            label: "5= Molto buona"
          }
        ]
      },
      {
        key: "WHOQOL_2",
        label: "2) È soddisfatto/a della sua salute?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_3",
        label:
          "3) In che misura i dolori fisici le impediscono di fare le cose che deve fare?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_4",
        label:
          "4) Ha bisogno di trattamenti o interventi medici per poter affrontare la vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_5",
        label: "5) Quanto si gode la vita?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_6",
        label:
          "6) In che misura lei pensa che la sua vita abbia un significato?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_7",
        label: "7) Riesce a concentrarsi nelle cose che fa?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_8",
        label: "8) Quanto si sente al sicuro nella sua vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_9",
        label:
          "9) L’ambiente in cui vive è sicuro per la salute?\n(nel senso del rumore, inquinamento, clima ed altre caratteristiche ambientali)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_10",
        label:
          "10) Ha l’energia necessaria per poter svolgere le attività di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_11",
        label: "11) Accetta di buon grado il suo aspetto esteriore?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_12",
        label:
          "12) Le sue risorse economiche le bastano per soddisfare i suoi bisogni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_13",
        label:
          "13) Le informazioni di cui dispone le bastano per la vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_14",
        label:
          "14) Ha la possibilità di dedicarsi ad attività di svago nel tempo libero?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_15",
        label: "15) In che misura riesce a muoversi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_16",
        label: "16) È soddisfatto/a di come dorme?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_17",
        label:
          "17) È soddisfatto/a di come riesce a fare le cose di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_18",
        label:
          "18) È soddisfatto/a della sua capacità di impegnarsi in attività?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_19",
        label: "19) È soddisfatto/a di sé stesso?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_20",
        label: "20) È soddisfatto/a dei suoi rapporti personali con gli altri?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_21",
        label: "21) È soddisfatto/a della sua vita sessuale? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_22",
        label: "22) È soddisfatto/a del sostegno che riceve dai suoi amici?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_23",
        label: "23) È soddisfatto/a delle condizioni della sua abitazione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_24",
        label:
          "24) È soddisfatto/a della disponibilità ed accessibilità dei servizi sanitari?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_25",
        label:
          "25) È soddisfatto/a dei mezzi di trasporto che ha a disposizione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_26",
        label:
          "26) Quanto spesso prova sentimenti negativi, come cattivo umore, disperazione, ansia o depressione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Mai"
          },
          {
            value: 2,
            label: "2= Raramente"
          },
          {
            value: 3,
            label: "3= Abbastanza Spesso"
          },
          {
            value: 4,
            label: "4= Molto Spesso"
          },
          {
            value: 5,
            label: "5= Sempre"
          }
        ]
      }
    ]
  },
  {
    type: "CGI",
    name: "CLINICAL GLOBAL IMPRESSION",
    codiceDataCompletamento: "DATA_CGI",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_CGI",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "CGI_0",
        label:
          "GRAVITA' DELLA MALATTIA: IN BASE ALLA VOSTRA ESPERIENZA CON QUESTO TIPO DI PAZIENTI QUANTO E' AMMALATO, DAL PUNTO DI VISTA PSICHICO IL PAZIENTE IN QUESTO MOMENTO?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0=Non è stato valutato"
          },
          {
            value: 1,
            label: "1= Normale"
          },
          {
            value: 2,
            label: "2= Solo marginalmente ammalato"
          },
          {
            value: 3,
            label: "3=lievemente ammalato"
          },
          {
            value: 4,
            label: "4= moderatamente ammalato"
          },
          {
            value: 5,
            label: "5=notevolmente ammalato"
          },
          {
            value: 6,
            label: "6=gravemente ammalato"
          },
          {
            value: 7,
            label: "7=è tra i pazienti più gravi"
          }
        ]
      },
      {
        key: "CGI_1",
        label:
          "MIGLIORAMENTO GLOBALE: IN CHE MISURA SONO CAMBIATE LE CONDIZIONI DEL PAZIENTE RISPETTO ALL'INIZIO DEL TRATTAMENTO? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0=Non è stato valutato"
          },
          {
            value: 1,
            label: "1= molto migliorate"
          },
          {
            value: 2,
            label: "2= moderatamente migliorate"
          },
          {
            value: 3,
            label: "3=lievemente migliorare"
          },
          {
            value: 4,
            label: "4= nessun cambiamento"
          },
          {
            value: 5,
            label: "5=lievemente peggiorate"
          },
          {
            value: 6,
            label: "6=moderatamente peggiorate"
          },
          {
            value: 7,
            label: "7=molto peggiorate"
          }
        ]
      },
      {
        key: "CGI_2",
        label:
          "INDICE DI EFFICACIA: VALUTARE QUESTO ITEM SOLO IN BASE ALL'EFFICACIA DEL TRATTAMENTO. SCEGLIERE LE VOCI CHE MEGLIO DESCRIVONO L'EFFETTO TERAPEUTICO E GLI EFFETTI COLLATERALI",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label:
              "Effetto terapeutico notevole e non ci sono effetti collaterali=1"
          },
          {
            value: 1,
            label:
              "Effetto teraputico notevole e non ci sono eff. Collaterali che interferiscono in misura significativa=2"
          },
          {
            value: 2,
            label:
              "effetto teraputico notevole ma effetti collaterali che interferiscono in maniera significativa=3"
          },
          {
            value: 3,
            label:
              "effetto terapuetico notievole ma gli effetti avversi rpevalgono sul terapeutico=4"
          },
          {
            value: 4,
            label:
              "Effetto terapeutico moderato e non ci sono effetti collaterali=5"
          },
          {
            value: 5,
            label:
              "Effetto teraputico moderato e non ci sono eff. Collaterali che interferiscono in misura significativa=6"
          },
          {
            value: 6,
            label:
              "Effetto teraputico moderato ma effetti collaterali che interferiscono in maniera significativa=7"
          },
          {
            value: 7,
            label:
              "Effetto terapuetico moderato ma gli effetti avversi rpevalgono sul terapeutico=8"
          },
          {
            value: 8,
            label:
              "Effetto terapeutico lieve e non ci sono effetti collaterali=9"
          },
          {
            value: 9,
            label:
              "Effetto teraputico lieve e non ci sono eff. Collaterali che interferiscono in misura significativa=10"
          },
          {
            value: 10,
            label:
              "Effetto teraputico lieve ma effetti collaterali che interferiscono in maniera significativa=11"
          },
          {
            value: 11,
            label:
              "Effetto terapuetico lieve ma gli effetti avversi prevalgono sul terapeutico=12"
          },
          {
            value: 12,
            label:
              "Effetto terapeutico immodificato o peggiorato e non ci sono effetti collaterali=13"
          },
          {
            value: 13,
            label:
              "Effetto teraputico immodificato o peggiorato e non ci sono eff. Collaterali che interferiscono in misura significativa=14"
          },
          {
            value: 14,
            label:
              "Effetto teraputico immodificato o peggiorato ma effetti collaterali che interferiscono in maniera significativa=15"
          },
          {
            value: 15,
            label:
              "Effetto terapuetico immodificato o peggiorato ma gli effetti avversi prevalgono sul terapeutico=16"
          }
        ]
      }
    ]
  },
  {
    type: "DBC",
    name: "LISTA DI CONTROLLO DELLO SVILUPPO COMPORTAMENTALE",
    codiceDataCompletamento: "DATA_DBC",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_DBC",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "DBC_an",
        label:
          "Grado di parentela o rapporto col bambino/a del soggetto che compila il questionario",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_an1",
        label: "Il bambino è",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Incapace di vedere / incapace di sentire"
          },
          {
            value: 2,
            label: "Incapace di usare le braccia / gambe"
          },
          {
            value: 3,
            label: "Incapace di parlare/ parla molto poco"
          },
          {
            value: 4,
            label: "Affetto da altro grave disturbo medico"
          }
        ]
      },
      {
        key: "DBC_an2",
        label: "Descrivete",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_an3",
        label: "Qual è la cosa che sa far meglio?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_an4",
        label: "Gli altri cosa trovano di bello o simpatico nel bambino/a?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_an5",
        label: "Quali sono le sue attività preferite?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_an6",
        label:
          "Secondo voi ci sono cose che fa bene quanto gli altri o meglio degli altri?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_an7",
        label:
          "Avete chiesto aiuto a qualcuno per la cura dei problemi emotivi o comportamenti del bambino/a ?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "DBC_an8",
        label: "Se sì, a chi l’avete chiesto?",
        if: [
          {
            key: "DBC_an7",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_0",
        label:
          "Istruzioni: Le frasi sottoindicate descrivono una serie di comportamenti che possibilmente non sempre sono applicabili al bambino\ndi cui vi prendete cura. Indicate la frase che descrive il comportamento attuale del bambino o quello che avete notato\nnegli ultimi sei mesi, circolettando il numero 2 se il comportamento descritto è molto vero o capita spesso. Circolettate\nil numero 1 se il comportamento è più o meno vero o capita a volte. Circolettate lo 0 se il comportamento non è vero.\n0 = non è vero per quanto vi risulta 1 = più o meno vero o capita a volte 2 = molto vero o capita molto spesso\nSe il bambino non è in grado di fare una data cosa, circolettate 0. Per esempio, se il bambino non parla, circolettate 0 in\nrisposta alla frase “Parla troppo o parla troppo velocemente”.",
        if: null,
        type: "description"
      },
      {
        key: "DBC_1",
        label: "Sembra depresso, giù di morale, scontento.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_2",
        label: "Evita di guardare gli altri direttamente negli occhi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_3",
        label: "Appare distaccato, in un mondo tutto suo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_4",
        label: "Si dimostra offensivo nel parlare, insulta gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_5",
        label: "Sistema oggetti e cose in modo preciso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_6",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_5",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_5",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_7",
        label: "Sbatte la testa.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_8",
        label: "Si eccita facilmente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_9",
        label: "Morde gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_10",
        label:
          "Non riesce a concentrarsi su un’attività per un minimo di tempo, non presta attenzione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_11",
        label: "Mastica o si mette in bocca oggetti o parti del corpo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_12",
        label:
          "Piange facilmente per un nonnulla o senza un motivo particolare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_13",
        label: "Si copre le orecchie quando sente un suono particolare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_14",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_13",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_13",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_15",
        label: 'Confonde l’uso dei pronomi, es. usa "tu" invece di "io".',
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_16",
        label: "Scappa via di proposito.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_17",
        label:
          "Illusioni: ha un’idea fissa o crede fermamente in qualcosa che non esiste.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_18",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_17",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_17",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_19",
        label: "Diventa ansioso deve rimanere solo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_20",
        label: "Non si dimostra affettuoso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_21",
        label:
          "Non reagisce alle emozioni degli altri, es. rimane impassibile se un familiare piange.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_22",
        label:
          "Si distrae facilmente da quello che sta facendo, es. a causa di un rumore.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_23",
        label: "Si lascia facilmente influenzare dagli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_24",
        label: "Mangia oggetti non commestibili, es. terra, erba, sapone.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_25",
        label:
          "Si agita eccessivamente quando è con persone che non conosce molto bene.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_26",
        label:
          "Ha paura di situazioni odi cose particolari, es. del buio o di insetti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_27",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_26",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_29",
        label: "Ha tick nervosi o fa smorfie.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_30",
        label: "Giocherella, picchietta o fa girare gli oggetti ripetutamente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_31",
        label:
          "Schizzinoso nel mangiare, oppure vuole mangiare sempre le stesse cose.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_32",
        label:
          "Divora il cibo, fa di tutto per mangiare di più, es. prende avanzi di cibo dalla pattumiera o ruba\ncose da mangiare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_33",
        label: "Si fissa su un’idea o su un’attività specifica.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_34",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_33",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_35",
        label: "Digrigna i denti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_36",
        label: "Ha incubi nel sonno. È sonnambulo/a.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_37",
        label:
          "Si arrabbia e fa i capricci. Es, pesta i piedi o sbatte la porta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_38",
        label: "Nasconde le cose.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_39",
        label: "Si picchia o si morde.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_40",
        label: "Mormora, grugnisce, si lamenta o emette altri suoni.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_41",
        label: "È impaziente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_42",
        label: "Ha un comportamento sessuale inappropriato con alter persone.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_43",
        label: "È impulsivo, agisce senza pensare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_44",
        label: "È irritabile.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_45",
        label: "È geloso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_46",
        label: "Tira calci o picchia gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_47",
        label: "Ha poca stima e fiducia di sé.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_48",
        label: "Ride o fa risolini senza un motivo apparente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_49",
        label: "Accende fuochi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_50",
        label:
          "Gli piace tenere in mano o giocare con oggetti poco comuni, es. un pezzo di spago o di legno oppure è\naffascinato da qualcosa in particolare, es. l’acqua.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_51",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_50",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_50",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_52",
        label: "Perde l’appetito.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_53",
        label: "Si masturba o espone gli organi genitali in pubblico.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_54",
        label: "Cambia di umore all’improvviso o senza un motivo apparente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_55",
        label:
          "Si muove lentamente, è poco attivo o non fa nulla. Es sta seduto a guardare gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_56",
        label: "È rumoroso o prepotente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_57",
        label: "È iperattivo, insofferente, incapace di star fermo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_58",
        label: "È estremamente affettuoso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_59",
        label:
          "Respira affannosamente, vomita soffre di mal di testa o dice di sentirsi poco bene senza motivo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_60",
        label: "Vuole essere il centro dell’attenzione, vuole farsi notare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_61",
        label: "Presta molta attenzione alle cose meccaniche.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_62",
        label: "Non avverte il pericolo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_63",
        label:
          "Preferisce la compagnia di adulti o bambini e non dei propri coetanei.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_64",
        label: "Preferisce far le cose da solo. Tende ad essere solitario.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_65",
        label: "Si preoccupa solo di una o due cose in particolare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_66",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_65",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_65",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_67",
        label:
          "Rifiuta di andare a scuola, al lavoro o al centro delle attività.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_68",
        label:
          "Fa movimenti ripetitivi con le braccia, col corpo, con la testa o con il viso. Es. Si dondola o agita le mani.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_69",
        label: "Non vuole essere abbracciato, toccato o accarezzato.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_70",
        label: "Ripete a pappagallo quello che gli dicono gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_71",
        label: "Ripete la stessa parola o frase in continuazione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_72",
        label: "Odora, assaggia o lecca oggetti di genere vario.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_73",
        label: "Si gratta o si spella.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_74",
        label: "Grida molto.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_75",
        label: "Dorme poco. Sonno disturbato o interrotto.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_76",
        label: "Fissa le luci o gli oggetti che girano.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_77",
        label: "Dorme troppo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_78",
        label:
          "Sporca il gabinetto, anche se sa come fare i sui bisogni. Gioca o imbratta muri e cose con le feci",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_79",
        label:
          "Parla sottovoce o ad alta voce o con un tono o con un ritmo insolito.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_80",
        label:
          "Spegne e accende le luci, versa acqua in continuazione o svolge attività ripetitive simili.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_81",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_80",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_80",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_82",
        label: "Ruba.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_83",
        label: "È testardo, disubbidiente e non collabora.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_84",
        label: "Si vergogna.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_85",
        label: "Si spoglia o butta via gli indumenti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_86",
        label: "Dice di saper fare cose che non è in grado di fare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_87",
        label: "Si mette vicinissimo agli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_88",
        label: "Vede o sente cose che non esistono. Ha delle allucinazioni.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_89",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_88",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_88",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_90",
        label: "Parla di suicidio.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_91",
        label: "Parla troppo o troppo in fretta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_92",
        label: "Parla da solo o a persone o ad oggetti immaginari.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_93",
        label: "Dice bugie.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_94",
        label:
          "Non collega i suoi pensieri, le sue idee sono slegate ed incoerenti ed il significato è difficile\nda seguire.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_95",
        label: "Si mostra teso, ansioso e preoccupato.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_96",
        label: "Tira o rompe oggetti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_97",
        label: "Cerca di manipolare o di provocare gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_98",
        label: "Reagisce poco al dolore.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_99",
        label:
          "Si mostra estremamente felice o contento senza un valido motivo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_100",
        label: "Fa movimenti insoliti o cammina in un modo curioso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_101",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_100",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_100",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_102",
        label:
          "Piccoli cambiamenti della routine o dell’ambiente lo sconvolgono o lo preoccupano.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_103",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_102",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_102",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_104",
        label:
          "Urina fuori dalla tazza del gabinetto, anche se sa come deve fare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_105",
        label: "È molto prepotente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_106",
        label: "Va in giro senza meta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_107",
        label: "Piagnucola o si lamenta troppo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_108",
        label:
          "Se il bambino ha dei problemi che non sono stati menzionati in questa lista, descriveteli",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_109",
        label:
          "In linea generale, oltre ad avere problemi dello sviluppo, secondo voi il vostro bambino ha\nanche dei problemi emotivi o comportamentali? Se non ne ha circolettate 0. se ha piccoli\nproblemi, circolettate 1. se ha grossi problemi, circolettate 2.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_110",
        label: "Disruptive/\nAntisocial",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_37",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_38",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_41",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_43",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_44",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_45",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_46",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_47",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_49",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_54",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_56",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_60",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_67",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_82",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_83",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_86",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_91",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_93",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_95",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_96",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_97",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_105",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_107",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_111",
        label: "Self Absorbed",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_24",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_29",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_30",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_32",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_35",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_39",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_40",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_48",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_50",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_53",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_62",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_68",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_72",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_74",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_76",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_78",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_80",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_85",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_96",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_98",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_100",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_104",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_106",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_112",
        label: "Communication \nDisturbance",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_33",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_58",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_61",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_63",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_65",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_70",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_71",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_79",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_87",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_92",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_99",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_113",
        label: "Anxiety",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_25",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_26",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_31",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_36",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_52",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_84",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_102",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_114",
        label: "Social Relating",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_55",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_59",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_64",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_69",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_77",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_115",
        label: "Total Behaviour Problem Score",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_17",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_22",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_24",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_25",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_26",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_29",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_30",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_31",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_32",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_33",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_35",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_36",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_37",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_38",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_39",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_40",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_41",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_42",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_43",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_44",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_45",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_46",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_47",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_48",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_49",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_50",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_52",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_53",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_54",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_55",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_56",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_58",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_59",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_60",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_61",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_62",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_63",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_64",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_65",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_67",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_68",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_69",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_70",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_71",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_72",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_73",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_74",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_75",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_76",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_77",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_78",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_79",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_80",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_82",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_83",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_84",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_85",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_86",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_87",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_88",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_90",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_91",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_92",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_93",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_94",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_95",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_96",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_97",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_98",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_99",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_100",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_102",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_104",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_105",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_106",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_107",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_109",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "PPC",
    name: "Parent Problem CheckList (PPC)",
    codiceDataCompletamento: "DATA_PPC",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_PPC",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "PPC_0",
        label:
          "Indichi il numero che meglio descrive la misura in cui questo è stato un problema per lei e il suo partner nelle ultime 4 settimane. Indichi zero se il problema non si è presentato ",
        if: null,
        type: "description"
      },
      {
        key: "PPC_1",
        label:
          "Disaccordo sulle regole domestiche (ad es.\nora di andare a dormire, spazi di gioco)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_2",
        label:
          "Disaccordo sul tipo di disciplina (ad es. dare\nschiaffi ai bambini)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_3",
        label: "Disaccordo su chi dovrebbe insegnare la\ndisciplina ai bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_4",
        label: "Litigare di fronte ai bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_5",
        label: "Mancanza di accordo tra i genitori",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_6",
        label: "Non poter restare soli a causa dei bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_7",
        label:
          "Disaccordo sulla suddivisione del carico di\nlavoro nell’accudimento dei bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_8",
        label:
          "Incapacità di risolvere i conflitti riguardo\nall’accudimento dei bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_9",
        label:
          "Le discussioni riguardo all'accudimento dei\nfigli diventano litigi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_10",
        label: "Un genitore ostacola l'altro, ad es. non\nappoggiandolo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_11",
        label: "Un genitore favorisce un figlio rispetto\nall'altro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_12",
        label:
          "Mancanza di dialogo tra genitori in merito all’\naccudimento dei figli",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_13",
        label: "Mancanza di dialogo in generale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_14",
        label: "Un genitore è più “morbido”, l'altro più\nsevero coi bambini ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_15",
        label:
          "I bambini si comportano peggio con un\ngenitore rispetto all'altro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_16",
        label:
          "Disaccordo su cosa si considera come\ncomportamento “disubbidiente”",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_18",
        label: "PUNTEGGIO TOTALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "PPC_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_14",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "Tanner",
    name: "Tanner Scale",
    codiceDataCompletamento: "DATA_Tanner_04",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_Tanner_04",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "Tanner_Sex",
        label: "Sesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Maschio"
          },
          {
            value: 1,
            label: "Femmina"
          }
        ]
      },
      {
        key: "Tanner_0",
        label: "Peluria",
        if: [
          {
            key: "Tanner_Sex",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      },
      {
        key: "Tanner_1",
        label: "Mammelle ",
        if: [
          {
            key: "Tanner_Sex",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      },
      {
        key: "Tanner_2",
        label: "Genitali",
        if: [
          {
            key: "Tanner_Sex",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      },
      {
        key: "Tanner_3",
        label: "Peluria",
        if: [
          {
            key: "Tanner_Sex",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      }
    ]
  },
  {
    type: "PedS",
    name: "PedsQL: Questionario sulla qualità della vita in età pediatrica",
    codiceDataCompletamento: "DATA_peds",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_peds",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "peds_0",
        label:
          "Nella pagina che segue c’è una lista di cose che potrebbero essere un problema\nper Suo/a figlio/a. Considerando tutto quello che è successo nell'ULTIMO mese, ci dica quanto\nciascuna di queste cose è un problema per Suo/a figlio/a, facendo una\ncrocetta su: 0 se non è mai un problema, 1 se non è quasi mai un problema, 2 se è qualche volta un problema, 3 se è spesso un problema, 4 se è quasi sempre un problema\n\nNon ci sono risposte giuste o sbagliate. Se non capisce una domanda, per favore chieda aiuto.\n",
        if: null,
        type: "description"
      },
      {
        key: "peds_1",
        label: "1. Camminare per più di 100 metri",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_2",
        label: "2. Correre",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_3",
        label: "3. Fare sport o attività fisica",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_4",
        label: "4. Sollevare qualcosa di pesante",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_5",
        label: "5. Fare il bagno o la doccia da solo/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_6",
        label: "6. Fare dei lavoretti di casa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_7",
        label: "7. Avere male o dolore",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_8",
        label: "8. Avere un basso livello di energia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_9",
        label: "1. Essere impaurito/a o spaventato/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_10",
        label: "2. Sentirsi triste",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_11",
        label: "3. Essere arrabbiato/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_12",
        label: "4. Avere difficoltà ad addormentarsi e/o a dormire",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_13",
        label: "5. Preoccuparsi per il proprio futuro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_14",
        label: "1. Andare d’accordo con gli/le altri/e ragazzini/e",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_15",
        label:
          "2. Gli/Le altri/e ragazzini/e non vogliono essere suoi/sue amici/che",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_16",
        label: "3. Essere preso/a in giro dagli/dalle altri/e ragazzini/e",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_17",
        label:
          "4. Non riuscire a fare quello che gli/le altri/e ragazzini/e della sua età riescono a fare",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_18",
        label:
          "5. Riuscire a fare quello che fanno gli/le altri/e ragazzini/e quando gioca con loro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_19",
        label: "1. Stare attento/a in classe",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_20",
        label: "2. Dimenticare le cose",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_21",
        label:
          "3. Riuscire a seguire le lezioni in classe e a finire i compiti a casa regolarmente",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_22",
        label: "4. Non andare a scuola perché non si sente bene",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_23",
        label: "5. Non andare a scuola per andare dal dottore o in ospedale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_24",
        label: "FUNZIONAMENTO FISICO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      },
      {
        key: "peds_25",
        label: "FUNZIONAMENTOEMOTIVO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      },
      {
        key: "peds_26",
        label: "FUNZIONAMENTO SOCIALE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_14",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_17",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_18",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      },
      {
        key: "peds_27",
        label: "FUNZIONAMENTO SCOLASTICO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_22",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "SDQ",
    name: "SDQ",
    codiceDataCompletamento: "DATE_sdq",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_sdq",
        label: "Data di completamento",
        type: "date"
      },
      {
        key: "SDQ_1",
        label: "Punteggio totale delle difficoltà",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_2",
        label: "Punteggio sintomi emotivi",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_3",
        label: "Punteggio disturbi delle condotta",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_4",
        label: "Punteggio iperattività",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_5",
        label: "Punteggio problemi di socializzazione ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_6",
        label: "Punteggio comportamento prosociale ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_7",
        label: "Punteggio impatto ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "DAWBA",
    name: "DAWBA",
    codiceDataCompletamento: "DATA_DAWBA",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_DAWBA",
        label: "Data di completamento",
        type: "date"
      },
      {
        key: "DAWBA_1",
        label: "Problemi medici",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_2",
        label: "Eventi di vita stressanti",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_3",
        label: "Stress Familiari ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_4",
        label: "Parenting",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_5",
        label: "Stress intervistato",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_6",
        label: "Stress partner",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "CBCL",
    name: "QUESTIONARIO SUL COMPORTAMENTO DEL BAMBINO ",
    codiceDataCompletamento: "DATA_CBCA",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_CBCA",
        label: "DATA",
        type: "date"
      },
      {
        key: "CBCA_1",
        label: "Scala Activities Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_2",
        label: "Scala Activities T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_3",
        label: "\n\nScala Social raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_4",
        label: "\n\nScala Social T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_5",
        label: "Scala School Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_6",
        label: "Scala School T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_7",
        label: "Total Comp Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_8",
        label: "Total Comp T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_9",
        label: "Anxious/Depressed Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_10",
        label: "Anxious/Depressed T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_11",
        label: "Withdrawn/Depressed Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_12",
        label: "Withdrawn/Depressed T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_13",
        label: "Somatic Complaints Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_14",
        label: "Somatic Complaints T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_15",
        label: "Social Problems Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_16",
        label: "Social Problems T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_17",
        label: "Thought Problems Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_18",
        label: "Thought Problems T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_19",
        label: "Attention Problems Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_20",
        label: "Attention Problems T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_21",
        label: "Rule-Breaking Behavior Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_22",
        label: "Rule-Breaking Behavior T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_23",
        label: "Aggressive Behavior Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_24",
        label: "Aggressive Behavior T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_25",
        label: "INT Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_26",
        label: "INT T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_27",
        label: "EXT Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_28",
        label: "EXT T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_30",
        label: "TOT Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_31",
        label: "TOT T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_32",
        label: "DSM-1 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_33",
        label: "DSM-1 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_34",
        label: "DSM-2 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_35",
        label: "DSM-2 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_36",
        label: "DSM-3 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_37",
        label: "DSM-3 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_38",
        label: "DSM-4 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_39",
        label: "DSM-4 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_40",
        label: "DSM-5 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_41",
        label: "DSM-5 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_42",
        label: "DSM-6 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_43",
        label: "DSM-6 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_44",
        label: "Sluggish Cognitive Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_45",
        label: "Sluggish Cognitive T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_46",
        label: "Obsessive-compulsive Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_47",
        label: "Obsessive-compulsive T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_48",
        label: "Stress Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_49",
        label: "Stress T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "BPRS T3",
    name: "BRIEF PSYCHIATRIC RATING SCALE (BPRS) (T3)",
    codiceDataCompletamento: "DATA_BPRS_t3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_BPRS_t3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "BPRS_t3_1",
        label: "1) PREOCCUPAZIONI SOMATICHE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_2",
        label: "2) ANSIA",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_3",
        label: "3) DEPRESSIONE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_4",
        label: "4) RISCHIO DI SUICIDIO",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_5",
        label: "5) SENTIMENTI DI COLPA",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_6",
        label: "6) OSTILITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_7",
        label: "7) ELEVAZIONE DEL TONO DELL’UMORE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_8",
        label: "8) GRANDIOSITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_9",
        label: "9) SOSPETTOSITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_10",
        label: "10) ALLUCINAZIONI ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_11",
        label: "11) CONTENUTO INSOLITO DEL PENSIERO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_12",
        label: "12) COMPORTAMENTO BIZZARRO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_13",
        label: "13) TRASCURATEZZA DELLA CURA DI SÉ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_14",
        label: "14) DISORIENTAMENTO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_15",
        label: "15) DISORGANIZZAZIONE CONCETTUALE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_16",
        label: "16) APPIATTIMENTO AFFETTIVO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_17",
        label: "17) ISOLAMENTO EMOTIVO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_18",
        label: "18) RALLENTAMENTO MOTORIO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_19",
        label: "19) TENSIONE MOTORIA ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_20",
        label: "20) MANCANZA DI COOPERAZIONE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_21",
        label: "21) ECCITAMENTO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_22",
        label: "22) DISTRAIBILITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_23",
        label: "23) IPERATTIVITÀ MOTORIA ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_t3_24",
        label: "24) MANIERISMI E POSTURE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      }
    ]
  },
  {
    type: "WHODAS 2.0 T3",
    name: "WHO DISABILITY ASSESSMENT SCHEDULE (WHODAS)  (T3)",
    codiceDataCompletamento: "DATA_WHODAS_T3_11",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_WHODAS_T3_11",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WHODAS_T3_0",
        label:
          "Questo questionario esplora le difficoltà che sono in relazione a problemi di salute. Per problemi di salute si intendono malattie o altri disturbi che possono essere di breve o di lunga durata, traumi, problemi mentali o emotivi e problemi con alcol o droghe.\nRipensi agli ultimi 30 giorni e risponda alle domande pensando a quanta difficoltà ha avuto nello svolgere le attività di seguito elencate.\nPer ogni domanda, deve indicare un solo numero per ciascuna voce.",
        if: null,
        type: "description"
      },
      {
        key: "WHODAS_T3_1",
        label: "S1) Stare in piedi per un lungo periodo, come per 30 minuti? ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_2",
        label:
          "S2) Prendersi cura della casa e della famiglia per quanto\nè di sua responsabilità?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_3",
        label:
          "S3) Imparare cose nuove, come, per esempio, imparare a raggiungere un posto nuovo?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_4",
        label:
          "S4) Quanti problemi ha avuto nel partecipare ad attività comunitarie (per esempio, feste, attività religiose o di altro tipo) come chiunque altro?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_5",
        label:
          "S5) Quanto è stato coinvolto emotivamente dal suo problema di salute?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_6",
        label: "S6) Concentrarsi nel fare qualcosa per dieci minuti?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_7",
        label: "S7) Camminare per una lunga distanza, come per un chilometro?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_8",
        label: "S8) Lavarsi tutto il corpo?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_9",
        label: "S9) Vestirsi?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_10",
        label: "S10)  Interagire con persone che non conosce?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_11",
        label: "S11) Mantenere un’amicizia?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_12",
        label: "S12) Svolgere l’attività lavorativa/scolastica quotidiana?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "Nessuna"
          },
          {
            value: 1,
            label: "Poca"
          },
          {
            value: 2,
            label: "Moderata"
          },
          {
            value: 3,
            label: "Molta"
          },
          {
            value: 4,
            label: "Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_T3_13",
        label:
          "H1) Complessivamente, negli ultimi 30 giorni, per quanti giorni ha avuto queste difficoltà?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_T3_14",
        label:
          "H2) Negli ultimi 30 giorni, per quanti giorni è stato impossibilitato a svolgere le attività o il lavoro abituali a causa delle sue condizioni di salute?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_T3_15",
        label:
          "H3) Negli ultimi 30 giorni, senza contare i giorni in cui è stato impossibilitato, per quanti giorni ha diminuito o ridotto le attività o il lavoro abituale a causa delle sue condizioni di salute?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_T3_16",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "WHODAS_T3_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T3_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ],
          divideBy: 12
        }
      }
    ]
  },
  {
    type: "SLOF T3",
    name: "SPECIFIC LEVELS OF FUNCTIONING SCALE (SLOF) (T3)",
    codiceDataCompletamento: "DATA_SLOF_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_SLOF_T3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "SLOF_T3_0",
        label:
          "Verifichi quale numero meglio descrive il caratteristico livello di funzionamento del soggetto per ogni voce elencata sotto. Sia il più accurato possibile. Se non è sicuro rispetto ad un determinato punteggio, chieda a qualcuno che conosce il paziente o consulti la cartella clinica. Segni un solo numero per ogni voce. Controlli di aver contrassegnato tutte le voci. ",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_a",
        label: "CURA DI Sé - CONDIZIONE FISICA",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_1",
        label: "1) Vista",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_T3_2",
        label: "2) Udito",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_T3_3",
        label: "3) Compromissione dell'eloquio",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_T3_4",
        label: "4) Deambulazione, uso delle gambe",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_T3_5",
        label: "5) Utilizzo di mani e braccia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_T3_b",
        label: "CURA DI Sé - COMPETENZE NELLA CURA DI Sé",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_6",
        label:
          "6) Andare alla toilette (usa correttamente la toilette, mantiene puliti sé e lo spazio) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_7",
        label:
          "7) Alimentazione (utilizza gli utensili correttamente, abitudini alimentari) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_8",
        label: "8) Igiene personale (corpo e denti, pulizia generale) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_9",
        label:
          "9) Vestirsi da soli (seleziona capi di abbigliamento adeguatamente; si veste autonomamente) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_10",
        label:
          "10) Cura della propria persona (capelli, trucco, aspetto generale) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_11",
        label: "11) Cura dei propri beni",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_12",
        label: "12) Cura del proprio spazio vitale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_c",
        label: "FUNZIONAMENTO SOCIALE – RELAZIONI INTERPERSONALI",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_13",
        label:
          "13) Tollera i contatti con gli altri (non si allontana o respinge) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_14",
        label: "14) Stabilisce i contatti con gli altri",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_15",
        label:
          "15) Comunica in modo efficace (discorso e gestualità comprensibili e attinenti) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_16",
        label: "16) Partecipa alle attività senza suggerimenti ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_17",
        label: "17) Partecipa a gruppi ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_18",
        label: "18) Allaccia e mantiene le amicizie ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_19",
        label: "19) Chiede aiuto quando necessita ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_d",
        label: "FUNZIONAMENTO SOCIALE – ACCETTABILITÀ SOCIALE",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_20",
        label: "20) Abusi verbali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_21",
        label: "21) Abusi fisici",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_22",
        label: "22) Distrugge beni",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_23",
        label: "23) E' aggressivo fisicamente verso se stesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_24",
        label: "24) Ha paura, piange, è appiccicoso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_25",
        label: "25) Si appropria di beni altrui senza autorizzazione",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_26",
        label:
          "26) Reitera i comportamenti (passi, oscillazioni, rumori, ecc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_T3_e",
        label: "COMPETENZE IN AMBITO COMUNITARIO - ATTIVITà",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_27",
        label:
          "27) Responsabilità domestiche (pulizia della casa, cucinare, lavare vestiti, ecc.) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_28",
        label:
          "28) Acquisti (selezione di articoli, scelta di negozi, pagamento di cassa)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_29",
        label:
          "29) Gestione delle proprie finanze (gestione del budget, pagamento delle bollette)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_30",
        label:
          "30) Uso del telefono (trovare il numero, digitare il numero, conversazione, ascolto)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_31",
        label: "31) Allontanamento dalla propria abitazione senza perdersi ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_32",
        label:
          "32) Utilizzo dei trasporti pubblici (selezionare percorso, usare gli orari, pagare tariffe, effettuare i trasferimenti) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_33",
        label:
          "33) Impiego del tempo libero (letture, visite agli amici, ascoltare musica, ecc.) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_34",
        label:
          "34) Riconoscere ed evitare pericoli comuni (traffico, incendio, ecc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_35",
        label:
          "35) Automedicazione (comprendere lo scopo, assumere come prescritto, riconoscere gli effetti collaterali) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_36",
        label:
          "36) Utilizzo dei revizi medici e di comunità (sapere a chi rivolgersi, come e quando usarli) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_37",
        label:
          "37) Lettura di base, scrittura e calcolo (sufficiente per le necessità quotidiane) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_T3_f",
        label: "COMPETENZE IN AMBITO COMUNITARIO - CAPACITà LAVORATIVE",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_38",
        label: "38) Possiede competenze lavorative",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_39",
        label: "39) Lavora con una supervisione minima",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_40",
        label:
          "40) Sostiene gli sforzi lavorativi (non si distrae facilmente, è capace di lavorare sotto stress) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_41",
        label: "41) Si presenta agli appuntamenti puntuale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_42",
        label: "42) Segue accuratamente le istruzioni verbali ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_43",
        label: "43) Completa i compiti assegnati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_T3_g",
        label: "ALTRE INFORMAZIONI",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_T3_44",
        label:
          "44) In base alla conoscenza di questa persona, ci sono altre abilità o aree problematiche non contemplate da questo questionario e rilevanti ai fini della capacità di questa persona di operare in modo indipendente? Se è così, si prega di specificare. ",
        if: null,
        type: "free-text"
      },
      {
        key: "SLOF_T3_45",
        label:
          "45) Quanto conosce le abilità e il comportamento della persona che ha appena valutato?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1. Per nulla"
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: "5. Molto bene"
          }
        ]
      },
      {
        key: "SLOF_T3_46",
        label: "46) Ha discusso questa valutazione con il soggetto?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 4,
            label: "No"
          },
          {
            value: 5,
            label: "Sì"
          }
        ]
      },
      {
        key: "SLOF_T3_46a",
        label:
          "46.a) Se SÌ, l’individuo concorda generalmente con la valutazione? ",
        if: [
          {
            key: "SLOF_T3_46",
            comparator: "=",
            value: 5
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 4,
            label: "No"
          },
          {
            value: 5,
            label: "Sì"
          }
        ]
      },
      {
        key: "SLOF_T3_46b",
        label: "46.b) Se NO, si prega di commentare ",
        if: [
          {
            key: "SLOF_T3_46",
            comparator: "=",
            value: 4
          }
        ],
        type: "free-text"
      },
      {
        key: "SLOF_T3_47",
        label: "PUNTEGGIO CURA DI SE'",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SLOF_T3_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_2",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_3",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_6",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_12",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "SLOF_T3_48",
        label: "PUNTEGGIO FUNZIONAMENTO SOCIALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SLOF_T3_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_14",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_15",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_16",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_17",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_18",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_19",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_20",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_21",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_22",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_25",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_26",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "SLOF_T3_49",
        label: "PUNTEGGIO ABILITA' SOCIALI",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SLOF_T3_27",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_28",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_29",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_30",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_31",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_32",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_33",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SLOF_T3_34",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "ECI T3",
    name: "Elixahuser Comorbidity Index (T3)",
    codiceDataCompletamento: "DATA_Elixahuser_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_Elixahuser_T3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "Elixahuser_T3_0",
        label:
          "Qui sotto troverà un elenco di disturbi fisici. Per ogni disturbo vi è uno specifico punteggio da assegnare. Si prega di segnare con una crocetta i disturbi di cui ha sofferto il partecipante nel corso della sua vita. Il punteggio totale è calcolato dalla somma dei singoli punteggi in colonna.",
        if: null,
        type: "description"
      },
      {
        key: "Elixahuser_T3_1",
        label: "Congestive heart failure",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_2",
        label: "Cardiac arrhythmias",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_3",
        label: "Valvular disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_4",
        label: "Pulmonary circulation disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_5",
        label: "Peripheral vascular disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_6",
        label: "Hypertension, uncomplicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_7",
        label: "Hypertension, complicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_8",
        label: "Paralysis",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_9",
        label: "Other neurological disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_10",
        label: "Chronic pulmonary disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_11",
        label: "Diabetes, uncomplicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_12",
        label: "Diabetes, complicated",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_13",
        label: "Hypothyroidism",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_14",
        label: "Renal failure",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_15",
        label: "Liver disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_16",
        label: "Peptic ulcer disease excluding bleeding",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_17",
        label: "AIDS/HIV",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_18",
        label: "Lymphoma",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_19",
        label: "Metastatic cancer",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_20",
        label: "Solid tumor without metastasis",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_21",
        label: "Rheumatoid arthritis/collagen vascular diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_22",
        label: "Coagulopathy",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_23",
        label: "Obesity",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_24",
        label: "Weight loss",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_25",
        label: "Fluid and electrolyte disorders",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_26",
        label: "Blood loss anemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_27",
        label: "Deficiency anemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_28",
        label: "Alcohol abuse",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_29",
        label: "Drug abuse",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_30",
        label: "Psychoses",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_31",
        label: "Depression",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No"
          },
          {
            value: 1,
            label: "Sì"
          }
        ]
      },
      {
        key: "Elixahuser_T3_TOTAL_SCORE",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "Elixahuser_T3_1",
              map: {
                "0": 0,
                "1": 9
              }
            },
            {
              key: "Elixahuser_T3_2",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_T3_3",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_T3_4",
              map: {
                "0": 0,
                "1": 6
              }
            },
            {
              key: "Elixahuser_T3_5",
              map: {
                "0": 0,
                "1": 3
              }
            },
            {
              key: "Elixahuser_T3_6",
              map: {
                "0": 0,
                "1": -1
              },
              onlyIf: {
                key: "Elixahuser_T3_7",
                value: 0,
                elseValue: 0
              }
            },
            {
              key: "Elixahuser_T3_7",
              map: {
                "0": 0,
                "1": -1
              }
            },
            {
              key: "Elixahuser_T3_8",
              map: {
                "0": 0,
                "1": 5
              }
            },
            {
              key: "Elixahuser_T3_9",
              map: {
                "0": 0,
                "1": 5
              }
            },
            {
              key: "Elixahuser_T3_10",
              map: {
                "0": 0,
                "1": 3
              }
            },
            {
              key: "Elixahuser_T3_11",
              map: {
                "0": 0,
                "1": 0
              },
              onlyIf: {
                key: "Elixahuser_T3_12",
                value: 0,
                elseValue: 0
              }
            },
            {
              key: "Elixahuser_T3_12",
              map: {
                "0": 0,
                "1": -3
              }
            },
            {
              key: "Elixahuser_T3_13",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_T3_14",
              map: {
                "0": 0,
                "1": 6
              }
            },
            {
              key: "Elixahuser_T3_15",
              map: {
                "0": 0,
                "1": 4
              }
            },
            {
              key: "Elixahuser_T3_16",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_T3_17",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_T3_18",
              map: {
                "0": 0,
                "1": 6
              }
            },
            {
              key: "Elixahuser_T3_19",
              map: {
                "0": 0,
                "1": 14
              }
            },
            {
              key: "Elixahuser_T3_20",
              map: {
                "0": 0,
                "1": 7
              },
              onlyIf: {
                key: "Elixahuser_T3_19",
                value: 0,
                elseValue: 0
              }
            },
            {
              key: "Elixahuser_T3_21",
              map: {
                "0": 0,
                "1": 0
              }
            },
            {
              key: "Elixahuser_T3_22",
              map: {
                "0": 0,
                "1": 11
              }
            },
            {
              key: "Elixahuser_T3_23",
              map: {
                "0": 0,
                "1": -5
              }
            },
            {
              key: "Elixahuser_T3_24",
              map: {
                "0": 0,
                "1": 9
              }
            },
            {
              key: "Elixahuser_T3_25",
              map: {
                "0": 0,
                "1": 11
              }
            },
            {
              key: "Elixahuser_T3_26",
              map: {
                "0": 0,
                "1": -3
              }
            },
            {
              key: "Elixahuser_T3_27",
              map: {
                "0": 0,
                "1": -2
              }
            },
            {
              key: "Elixahuser_T3_28",
              map: {
                "0": 0,
                "1": -1
              }
            },
            {
              key: "Elixahuser_T3_29",
              map: {
                "0": 0,
                "1": -7
              }
            },
            {
              key: "Elixahuser_T3_30",
              map: {
                "0": 0,
                "1": -5
              }
            },
            {
              key: "Elixahuser_T3_31",
              map: {
                "0": 0,
                "1": -5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "EQ5D T3",
    name: "EQ5D (T3)",
    codiceDataCompletamento: "DATE_EQ5D_T3_FORM",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATE_EQ5D_T3_FORM",
        label: "Date",
        type: "date"
      },
      {
        key: "EQ5D_T3_1",
        label: "MOBILITA'",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non ho difficoltà nel camminare= 1"
          },
          {
            value: 2,
            label: "Ho lievi difficoltà nel camminare = 2"
          },
          {
            value: 3,
            label: "Ho moderate difficoltà nel camminare= 3"
          },
          {
            value: 4,
            label: "Ho gravi difficoltà nel camminare=4"
          },
          {
            value: 5,
            label: "Non sono in grado di camminare=5"
          }
        ]
      },
      {
        key: "EQ5D_T3_2",
        label: "CURA DI SE'",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non ho difficoltà nel lavarmi o vestirmi=1"
          },
          {
            value: 2,
            label: "Ho lievi difficoltà nel lavarmi o vestirmi=2"
          },
          {
            value: 3,
            label: "Ho moderate difficoltà nel lavarmi o vestirmi=3"
          },
          {
            value: 4,
            label: "Ho gravi difficoltà nel lavarmi o vestirmi=4"
          },
          {
            value: 5,
            label: "Non sono in grado di lavarmi o vestirmi=5"
          }
        ]
      },
      {
        key: "EQ5D_T3_3",
        label: "ATTIVITA' QUOTIDIANE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Non ho difficoltà nello svolgimento delle attività abituali=1"
          },
          {
            value: 2,
            label:
              "Ho lievi difficoltà nello svolgimento delle attività abituali=2"
          },
          {
            value: 3,
            label:
              "Ho moderate difficoltà nello svolgimento delle attività abituali=3"
          },
          {
            value: 4,
            label:
              "Ho gravi difficoltà nello svolgimento delle attività abituali=4"
          },
          {
            value: 5,
            label: "Non sono in grado di svolgere le mie attività abituali=5"
          }
        ]
      },
      {
        key: "EQ5D_T3_4",
        label: "DOLORE/FASTIODIO",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non provo alcun dolore o fastidio=1"
          },
          {
            value: 2,
            label: "Provo lieve dolore o fastidio=2"
          },
          {
            value: 3,
            label: "Provo moderato dolore o fastidio=3"
          },
          {
            value: 4,
            label: "Provo grave dolore o fastidio=4"
          },
          {
            value: 5,
            label: "Provo estremo dolore o fastidio=5"
          }
        ]
      },
      {
        key: "EQ5D_T3_5",
        label: "ANSIA/DEPRESSIONE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Non sono ansioso/a o depresso/a=1"
          },
          {
            value: 2,
            label: "Sono lievemente ansioso/a o depresso/a=2"
          },
          {
            value: 3,
            label: "Sono moderatamente ansioso/a o depresso/a=3"
          },
          {
            value: 4,
            label: "Sono gravemente ansioso/a o depresso/a=4"
          },
          {
            value: 5,
            label: "Sono estremamente ansioso/a o depresso/a=5"
          }
        ]
      },
      {
        key: "EQ5D_T3_6",
        label: "COME VALUTI LA TUA SALUTE OGGI",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 100
        },
        step: "any",
        auto: null
      },
      {
        key: "EQ5D_T3_7",
        label: "PUNTEGGIO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "EQ5D_T3_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_T3_2",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_T3_3",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_T3_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "EQ5D_T3_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "PSQI T3",
    name: "PSQI_T3 questionnaire",
    codiceDataCompletamento: "DATA_PSQI_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_PSQI_T3",
        label: "Date",
        type: "date"
      },
      {
        key: "PSQI_T3_0",
        label:
          "Le seguenti domande si riferiscono alle tue abituali abitudini di sonno solo durante l'ultimo mese. Le tue risposte dovrebbero indicare la risposta più accurata per la maggior parte dei giorni e delle notti nell'ultimo mese. Si prega di rispondere a tutte le domande.\n",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_T3_1",
        label:
          "1. Durante l'ultimo mese, a che ora sei andato a letto di solito la sera",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_T3_2",
        label:
          "2.  Durante l'ultimo mese, quanto tempo (in minuti) hai impiegato di solito per addormentarti ogni notte?\n",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_T3_3",
        label:
          "3. Durante l'ultimo mese, a che ora ti sei alzato di solito la mattina?",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_T3_4",
        label:
          "4. Durante il mese scorso, quante ore di sonno effettivo hai avuto di notte? (Questo potrebbe essere diverso dal numero di ore trascorse a letto).",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_T3_5",
        label:
          "5. Durante l'ultimo mese, quante volte hai avuto problemi a dormire perché:",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_T3_6",
        label: "a. Era Impossibile addormentarsi entro 30 minuti",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese= 0"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana = 1"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana=2"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana = 3"
          }
        ]
      },
      {
        key: "PSQI_T3_7",
        label: "b. Si è svegliato nel cuore della notte o al mattino presto",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_8",
        label: "c. Ha dovuto alzarsi per usare il bagno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_9",
        label: "d. Non riusciva a respirare bene",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_10",
        label: "e. Tossiva o russava",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_11",
        label: "f. Sentiva troppo freddo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_12",
        label: "g. Sentiva troppo caldo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_13",
        label: "h. Ha avuto gli incubi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_14",
        label: "i. Provava dolore",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_15",
        label: "j. Altri motivi, descrivere",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_T3_16",
        label:
          "Quante volte nell'ultimo mese hai avuto problemi a dormire a causa di questo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_17",
        label:
          "6. Durante l'ultimo mese, come valuteresti la qualità del sonno in generale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Molto bene"
          },
          {
            value: 1,
            label: "Abbastanza bene"
          },
          {
            value: 2,
            label: "Abbastanza male"
          },
          {
            value: 3,
            label: "Molto male"
          }
        ]
      },
      {
        key: "PSQI_T3_18",
        label:
          "7. Durante l'ultimo mese, quante volte hai preso medicine per aiutarti a dormire (prescritte o meno)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_19",
        label:
          "8. Durante l'ultimo mese, quante volte hai avuto problemi a rimanere sveglio mentre guidi, mangi o ti impegni in attività sociali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_20",
        label:
          "9. Durante l'ultimo mese, quanto è stato un problema per te mantenere abbastanza entusiasmo per fare le cose",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Per niente"
          },
          {
            value: 1,
            label: "Solo un piccolo problema"
          },
          {
            value: 2,
            label: "Per certi versi un problema"
          },
          {
            value: 3,
            label: "Un grosso problema"
          }
        ]
      },
      {
        key: "PSQI_T3_21",
        label: "10. Hai un compagno di letto o un compagno di stanza",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Dormo da solo"
          },
          {
            value: 1,
            label: "Partner/coinquilini in altra stanza"
          },
          {
            value: 2,
            label: "Partner/coinquilini nella stessa stanza ma letto diverso"
          },
          {
            value: 3,
            label: "Partner nello stesso letto"
          }
        ]
      },
      {
        key: "PSQI_T3_22",
        label:
          "Se hai un compagno di stanza o un compagno di letto, chiedigli quante volte nell'ultimo mese hai avuto\n",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_T3_23",
        label: "a. Forte russamento",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_24",
        label: "b. Lunghe pause tra un respiro e l'altro durante il sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_25",
        label: "c. Gambe che si contraggono o si agitano mentre dormi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_26",
        label: "d. Episodi di disorientamento o confusione durante il sonno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_27",
        label:
          "e. Altra irrequietezza mentre dormi; Si prega di descrivere...\n",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_T3_28",
        label:
          "Quante volte nell'ultimo mese hai provato irrequietezza mentre dormivi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Non durante lo scorso mese"
          },
          {
            value: 1,
            label: "Meno di una volta a settimana"
          },
          {
            value: 2,
            label: "Una o due volte alla settimana"
          },
          {
            value: 3,
            label: "Tre o più volte alla settimana"
          }
        ]
      },
      {
        key: "PSQI_T3_Durata",
        label:
          "DURATION OF SLEEP: IF Q4 > 7, THEN set value to 0\n\t\t\tIF Q4 < 7 and > 6, THEN set value to 1\n\t\t\tIF Q4 < 6 and > 5, THEN set value to 2\n\t\t\tIF Q4 < 5, THEN set value to 3\nMinimum Score = 0 (better); Maximum Score = 3 (worse)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_ PSQI_T3DISTB",
        label:
          "SLEEP DISTURBANCE: IF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) = 0, THEN set value to 0\nIF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) > 1 and < 9, THEN set value to 1\nIF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) > 9 and < 18, THEN set value to 2\nIF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) > 18, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_SLEEP LATENCY",
        label:
          "SLEEP LATENCY: First, recode Q2 into Q2new thusly:\nIF Q2 > 0 and < 15, THEN set value of Q2new to 0\nIF Q2 > 15 and < 30, THEN set value of Q2new to 1\nIF Q2 > 30 and < 60, THEN set value of Q2new to 2\nIF Q2 > 60, THEN set value of Q2new to 3\nNext\nIF Q5a + Q2new = 0, THEN set value to 0\nIF Q5a + Q2new > 1 and < 2, THEN set value to 1\nIF Q5a + Q2new > 3 and < 4, THEN set value to 2\nIF Q5a + Q2new > 5 and < 6, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_DYSF",
        label:
          "DAY DYSFUNCTION DUE TO SLEEPINESS: IF Q8 + Q9 = 0, THEN set value to 0\n\t\t\tIF Q8 + Q9 > 1 and < 2, THEN set value to 1\n\t\t\tIF Q8 + Q9 > 3 and < 4, THEN set value to 2\n\t\t\tIF Q8 + Q9 > 5 and < 6, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_SE",
        label:
          "SLEEP EFFICIENCY: Diffsec = Difference in seconds between day and time of day Q1 and day Q3\n\t\tDiffhour = Absolute value of diffsec / 3600\n\t             newtib =IF diffhour > 24, then newtib = diffhour – 24\n\t           IF diffhour < 24, THEN newtib = diffhour\n(NOTE, THE ABOVE JUST CALCULATES THE HOURS BETWEEN GNT (Q1) AND GMT (Q3))\n\t\ttmphse = (Q4 / newtib) * 100 IF tmphse > 85, THEN set value to 0\n\t\tIF tmphse < 85 and > 75, THEN set value to 1\n\t\tIF tmphse < 75 and > 65, THEN set value to 2\n\t\tIF tmphse < 65, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_OSP",
        label: "OVERALL SLEEP QUALITY (see Q6)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_MEDS ",
        label: "NEED MEDS TO SLEEP (seeQ7)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_T3_TOT",
        label: "TOTAL",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "SF-36 T3",
    name: "SF-36_T3 questionnaire",
    codiceDataCompletamento: "DATA_SF-36_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      }
    ],
    domande: [
      {
        key: "DATA_SF-36_T3",
        label: "Date",
        type: "date"
      },
      {
        key: "SF-36_T3_0",
        label:
          "La preghiamo di rispondere alle 36 domande dell'Indagine sulla salute in modo completo, onesto e senza interruzioni per la maggior parte dei giorni e delle notti dell'ultimo mese. Rispondere a tutte le domandE",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T3_1",
        label: "In generale, direbbe che la sua salute è",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Eccellente"
          },
          {
            value: 2,
            label: "Molto buona"
          },
          {
            value: 3,
            label: "Buona"
          },
          {
            value: 4,
            label: "Discreta"
          },
          {
            value: 5,
            label: "Povera"
          }
        ]
      },
      {
        key: "SF-36_T3_2",
        label:
          "Rispetto a un anno fa, come giudica il suo stato di salute in generale?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Molto meglio di un anno fa=1"
          },
          {
            value: 2,
            label: "Meglio di un anno fa=2"
          },
          {
            value: 3,
            label: "Lo stesso e=3"
          },
          {
            value: 4,
            label: "In qualche modo peggio dell'anno scorso =4"
          },
          {
            value: 5,
            label: "Molto peggio dell'anno scorso=5"
          }
        ]
      },
      {
        key: "SF-36_T3_3",
        label:
          "LIMITI DELLE ATTIVITÀ: Le seguenti voci riguardano le attività che potreste svolgere durante una giornata tipica. La sua salute la limita in queste attività? Se sì, quanto?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T3_4",
        label:
          "Attività vigorose, come la corsa, il sollevamento di oggetti pesanti, la partecipazione a sport faticosi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_5",
        label:
          "Attività Moderatamente, come spostare un tavolo, spingere un aspirapolvere, giocare a bowling o giocare a golf.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_6",
        label: "Alzare pesi o portare la spesa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_7",
        label: "Salire più piani di scale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_8",
        label: "Salire una rampa di scale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_9",
        label: "Piegarsi, inginocchiarsi o abbassarsi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_10",
        label: "Camminare per più di un chilometro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_11",
        label: "Camminare per diversi isolati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_12",
        label: "Camminare per un isolato",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_13",
        label: "Fare il bagno o vestirsi da soli",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì, Molto limitato"
          },
          {
            value: 2,
            label: "Sì, limitato in parte"
          },
          {
            value: 3,
            label: "Non limitato"
          }
        ]
      },
      {
        key: "SF-36_T3_14",
        label:
          "PROBLEMI DI SALUTE FISICA:\nNelle ultime 4 settimane, ha avuto uno dei seguenti problemi con il lavoro o con altre attività quotidiane regolari a causa della sua salute fisica?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T3_15",
        label:
          "Ridurre la quantità di tempo dedicata al lavoro o ad altre attività.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì=1"
          },
          {
            value: 2,
            label: "No=2"
          }
        ]
      },
      {
        key: "SF-36_T3_16",
        label: "Risultati inferiori a quelli desiderati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T3_17",
        label: "Era limitato nel tipo di lavoro o in altre attività",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T3_18",
        label:
          "Ha avuto difficoltà a svolgere il lavoro o altre attività (per esempio, ha richiesto uno sforzo supplementare)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T3_19",
        label:
          "PROBLEMI DI SALUTE EMOTIVA: Nel corso delle ultime 4 settimane, ha avuto uno dei seguenti problemi con il lavoro o con altre attività quotidiane regolari a causa di problemi emotivi (come sentirsi depresso o ansioso)?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T3_20",
        label:
          "Ridurre la quantità di tempo dedicata al lavoro o ad altre attività.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T3_21",
        label: "Risultati inferiori a quelli desiderati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T3_22",
        label:
          "Non ha svolto il lavoro o altre attività con la stessa attenzione di sempre",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T3_23",
        label:
          "ATTIVITÀ SOCIALI: I problemi emotivi hanno interferito con le sue normali attività sociali con la famiglia, gli amici, i vicini o i gruppi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente=1"
          },
          {
            value: 2,
            label: "Leggermente=2"
          },
          {
            value: 3,
            label: "Moderatamente=3"
          },
          {
            value: 4,
            label: "Grave=4"
          },
          {
            value: 5,
            label: "Molto grave=5"
          }
        ]
      },
      {
        key: "SF-36_T3_24",
        label:
          "DOLORE: Quanto dolore corporeo ha avuto nelle ultime 4 settimane?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Nessuno=1"
          },
          {
            value: 2,
            label: "Molto lieve=2"
          },
          {
            value: 3,
            label: "Lievi=3"
          },
          {
            value: 4,
            label: "Moderatamente=4"
          },
          {
            value: 5,
            label: "Grave=5"
          },
          {
            value: 6,
            label: "Molto grave=6"
          }
        ]
      },
      {
        key: "SF-36_T3_25",
        label:
          "Nelle ultime 4 settimane, quanto il dolore ha interferito con il suo normale lavoro (compreso il lavoro fuori casa e i lavori domestici)?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente=1"
          },
          {
            value: 2,
            label: "Poco=2"
          },
          {
            value: 3,
            label: "Moderatamente=3"
          },
          {
            value: 4,
            label: "Abbastanza=4"
          },
          {
            value: 5,
            label: "Extremely=5"
          }
        ]
      },
      {
        key: "SF-36_T3_26",
        label:
          "ENERGIA ED EMOZIONI:\nQueste domande riguardano come vi sentite e come sono andate le cose nelle ultime 4 settimane. Per ogni domanda, date la risposta che più si avvicina al modo in cui vi siete sentiti.",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T3_27",
        label: "Si è sentito pieno di energia?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo=1"
          },
          {
            value: 2,
            label: "La maggior parte del tempo=2"
          },
          {
            value: 3,
            label: "Buona parte del tempo=3"
          },
          {
            value: 4,
            label: "Un po' di tempo=4"
          },
          {
            value: 5,
            label: "Poco tempo=5"
          },
          {
            value: 6,
            label: "Per niente=6"
          }
        ]
      },
      {
        key: "SF-36_T3_28",
        label: "È stato una persona molto nervosa?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_29",
        label:
          "Si è sentito così giù di morale che niente riusciva a tirarlo su?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_30",
        label: "Si è sentito calmo e tranquillo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_31",
        label: "Aveva molta energia?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_32",
        label: "Si è sentito giù di morale e triste?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_33",
        label: "Si è sentito esaurito?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_34",
        label: "È stata una persona felice?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_35",
        label: "Si è sentito stanco?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_36",
        label:
          "ATTIVITÀ SOCIALI: Nelle ultime 4 settimane, per quanto tempo la sua salute fisica o i suoi problemi emotivi hanno interferito con le sue attività sociali (come le visite agli amici, ai parenti, ecc.)?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Tutto il tempo"
          },
          {
            value: 2,
            label: "La maggior parte del tempo"
          },
          {
            value: 3,
            label: "Buona parte del tempo"
          },
          {
            value: 4,
            label: "Un po' di tempo"
          },
          {
            value: 5,
            label: "Poco tempo"
          },
          {
            value: 6,
            label: "Per niente"
          }
        ]
      },
      {
        key: "SF-36_T3_37",
        label:
          "SALUTE GENERALE: Quanto è vera o falsa per lei ciascuna delle seguenti affermazioni?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T3_38",
        label: "Sembra che io mi ammali un po' più facilmente di altre persone",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero=1"
          },
          {
            value: 2,
            label: "Abbastanza vero=2"
          },
          {
            value: 3,
            label: "Non lo so=3"
          },
          {
            value: 4,
            label: "Abbastanza falso=4"
          },
          {
            value: 5,
            label: "Assolutamente falso=5"
          }
        ]
      },
      {
        key: "SF-36_T3_39",
        label: "Sono in salute come chiunque altro conosca",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero"
          },
          {
            value: 2,
            label: "Abbastanza vero"
          },
          {
            value: 3,
            label: "Non lo so"
          },
          {
            value: 4,
            label: "Abbastanza falso"
          },
          {
            value: 5,
            label: "Assolutamente falso"
          }
        ]
      },
      {
        key: "SF-36_T3_40",
        label: "Mi aspetto che la mia salute peggiori",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero"
          },
          {
            value: 2,
            label: "Abbastanza vero"
          },
          {
            value: 3,
            label: "Non lo so"
          },
          {
            value: 4,
            label: "Abbastanza falso"
          },
          {
            value: 5,
            label: "Assolutamente falso"
          }
        ]
      },
      {
        key: "SF-36_T3_41",
        label: "La mia salute è eccellente",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Assolutamente vero"
          },
          {
            value: 2,
            label: "Abbastanza vero"
          },
          {
            value: 3,
            label: "Non lo so"
          },
          {
            value: 4,
            label: "Abbastanza falso"
          },
          {
            value: 5,
            label: "Assolutamente falso"
          }
        ]
      },
      {
        key: "SF-36_T3_42",
        label: "FUNZIONAMENTO FISICO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_6",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_12",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T3_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            }
          ],
          divideBy: 10
        }
      },
      {
        key: "SF-36_T3_43",
        label: "LIMITAZIONI DI RUOLO DOVUTE ALLA SALUTE FISICA",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_15",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T3_16",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T3_17",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T3_18",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_T3_44",
        label: "LIMITAZIONI DI RUOLO DOVUTE ALLA SALUTE EMOTIVA",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_20",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T3_21",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T3_22",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 3
        }
      },
      {
        key: "SF-36_T3_45",
        label: "ENERGIA/AFFATICAMENTO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_27",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_31",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_33",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_35",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_T3_46",
        label: "BENESSERE EMOTIVO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_28",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_29",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_30",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_32",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_34",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            }
          ],
          divideBy: 5
        }
      },
      {
        key: "SF-36_T3_47",
        label: "FUNZIONAMENTO SOCIALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_36",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_T3_48",
        label: "DOLORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6
              }
            },
            {
              key: "SF-36_T3_25",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_T3_49",
        label: "SALUTE GENERALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T3_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T3_38",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T3_39",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T3_40",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T3_41",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 5
        }
      }
    ]
  },
  {
    type: "WHOQOL-BREF T3",
    name:
      "WORLD HEALTH ORGANIZATION QUALITY OF LIFE - Versione breve  (WHOQOL_T3-Bref)",
    codiceDataCompletamento: "DATA_WHOQOL_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_WHOQOL_T3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WHOQOL_T3_0",
        label:
          "Questo questionario riguarda quello che Lei pensa della qualità della Sua vita, della Sua salute, e di altri aspetti della Sua vita. Per favore, risponda a tutte le domande:\nse è incerto sulla risposta da fornire ad una domanda, scelga, per favore, quella che corrisponde di più alla sua opinione; spesso si tratterà della risposta che Le è venuta in mente per prima.\nPer favore, nel rispondere tenga conto delle sue abitudini di vita, delle Sue speranze, dei Suoi gusti e delle Sue preoccupazioni e pensi a quello che Le è successo negli ultimi 15 giorni. Lei deve fare un segno sul numero che meglio esprime in che misura Lei ha ricevuto l’aiuto di cui aveva bisogno negli ultimi 15 giorni.\nQuindi, Lei deve fare un segno sul numero 4 se ha ricevuto “Molto” aiuto dagli altri, o sul numero 1 se non ha ricevuto “Per niente” l’aiuto di cui aveva bisogno.\nLegga attentamente ogni domanda, rifletta sui Suoi stati d’animo e, come Le abbiamo già suggerito, faccia un segno sulla risposta che meglio corrisponde alla sua opinione o ai sui sentimenti.",
        if: null,
        type: "description"
      },
      {
        key: "WHOQOL_T3_1",
        label: "1) Come valuta la qualità della sua vita?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto cattiva"
          },
          {
            value: 2,
            label: "2= Cattiva"
          },
          {
            value: 3,
            label: "3= Nè cattiva nè buona"
          },
          {
            value: 4,
            label: "4= Buona"
          },
          {
            value: 5,
            label: "5= Molto buona"
          }
        ]
      },
      {
        key: "WHOQOL_T3_2",
        label: "2) È soddisfatto/a della sua salute?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_3",
        label:
          "3) In che misura i dolori fisici le impediscono di fare le cose che deve fare?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_4",
        label:
          "4) Ha bisogno di trattamenti o interventi medici per poter affrontare la vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_5",
        label: "5) Quanto si gode la vita?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_6",
        label:
          "6) In che misura lei pensa che la sua vita abbia un significato?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_7",
        label: "7) Riesce a concentrarsi nelle cose che fa?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_8",
        label: "8) Quanto si sente al sicuro nella sua vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_9",
        label:
          "9) L’ambiente in cui vive è sicuro per la salute?\n(nel senso del rumore, inquinamento, clima ed altre caratteristiche ambientali)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_10",
        label:
          "10) Ha l’energia necessaria per poter svolgere le attività di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_T3_11",
        label: "11) Accetta di buon grado il suo aspetto esteriore?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_12",
        label:
          "12) Le sue risorse economiche le bastano per soddisfare i suoi bisogni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_T3_13",
        label:
          "13) Le informazioni di cui dispone le bastano per la vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_T3_14",
        label:
          "14) Ha la possibilità di dedicarsi ad attività di svago nel tempo libero?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_T3_15",
        label: "15) In che misura riesce a muoversi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_T3_16",
        label: "16) È soddisfatto/a di come dorme?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_17",
        label:
          "17) È soddisfatto/a di come riesce a fare le cose di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_18",
        label:
          "18) È soddisfatto/a della sua capacità di impegnarsi in attività?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_19",
        label: "19) È soddisfatto/a di sé stesso?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_20",
        label: "20) È soddisfatto/a dei suoi rapporti personali con gli altri?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_21",
        label: "21) È soddisfatto/a della sua vita sessuale? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_22",
        label: "22) È soddisfatto/a del sostegno che riceve dai suoi amici?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_23",
        label: "23) È soddisfatto/a delle condizioni della sua abitazione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_24",
        label:
          "24) È soddisfatto/a della disponibilità ed accessibilità dei servizi sanitari?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_25",
        label:
          "25) È soddisfatto/a dei mezzi di trasporto che ha a disposizione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_T3_26",
        label:
          "26) Quanto spesso prova sentimenti negativi, come cattivo umore, disperazione, ansia o depressione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Mai"
          },
          {
            value: 2,
            label: "2= Raramente"
          },
          {
            value: 3,
            label: "3= Abbastanza Spesso"
          },
          {
            value: 4,
            label: "4= Molto Spesso"
          },
          {
            value: 5,
            label: "5= Sempre"
          }
        ]
      }
    ]
  },
  {
    type: "CGI T3",
    name: "CLINICAL GLOBAL IMPRESSION",
    codiceDataCompletamento: "DATA_CGI_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_CGI_T3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "CGI_T3_0",
        label:
          "GRAVITA' DELLA MALATTIA: IN BASE ALLA VOSTRA ESPERIENZA CON QUESTO TIPO DI PAZIENTI QUANTO E' AMMALATO, DAL PUNTO DI VISTA PSICHICO IL PAZIENTE IN QUESTO MOMENTO?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0=Non è stato valutato"
          },
          {
            value: 1,
            label: "1= Normale"
          },
          {
            value: 2,
            label: "2= Solo marginalmente ammalato"
          },
          {
            value: 3,
            label: "3=lievemente ammalato"
          },
          {
            value: 4,
            label: "4= moderatamente ammalato"
          },
          {
            value: 5,
            label: "5=notevolmente ammalato"
          },
          {
            value: 6,
            label: "6=gravemente ammalato"
          },
          {
            value: 7,
            label: "7=è tra i pazienti più gravi"
          }
        ]
      },
      {
        key: "CGI_T3_1",
        label:
          "MIGLIORAMENTO GLOBALE: IN CHE MISURA SONO CAMBIATE LE CONDIZIONI DEL PAZIENTE RISPETTO ALL'INIZIO DEL TRATTAMENTO? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0=Non è stato valutato"
          },
          {
            value: 1,
            label: "1= molto migliorate"
          },
          {
            value: 2,
            label: "2= moderatamente migliorate"
          },
          {
            value: 3,
            label: "3=lievemente migliorare"
          },
          {
            value: 4,
            label: "4= nessun cambiamento"
          },
          {
            value: 5,
            label: "5=lievemente peggiorate"
          },
          {
            value: 6,
            label: "6=moderatamente peggiorate"
          },
          {
            value: 7,
            label: "7=molto peggiorate"
          }
        ]
      },
      {
        key: "CGI_T3_2",
        label:
          "INDICE DI EFFICACIA: VALUTARE QUESTO ITEM SOLO IN BASE ALL'EFFICACIA DEL TRATTAMENTO. SCEGLIERE LE VOCI CHE MEGLIO DESCRIVONO L'EFFETTO TERAPEUTICO E GLI EFFETTI COLLATERALI",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label:
              "Effetto terapeutico notevole e non ci sono effetti collaterali=1"
          },
          {
            value: 1,
            label:
              "Effetto teraputico notevole e non ci sono eff. Collaterali che interferiscono in misura significativa=2"
          },
          {
            value: 2,
            label:
              "effetto teraputico notevole ma effetti collaterali che interferiscono in maniera significativa=3"
          },
          {
            value: 3,
            label:
              "effetto terapuetico notievole ma gli effetti avversi rpevalgono sul terapeutico=4"
          },
          {
            value: 4,
            label:
              "Effetto terapeutico moderato e non ci sono effetti collaterali=5"
          },
          {
            value: 5,
            label:
              "Effetto teraputico moderato e non ci sono eff. Collaterali che interferiscono in misura significativa=6"
          },
          {
            value: 6,
            label:
              "Effetto teraputico moderato ma effetti collaterali che interferiscono in maniera significativa=7"
          },
          {
            value: 7,
            label:
              "Effetto terapuetico moderato ma gli effetti avversi rpevalgono sul terapeutico=8"
          },
          {
            value: 8,
            label:
              "Effetto terapeutico lieve e non ci sono effetti collaterali=9"
          },
          {
            value: 9,
            label:
              "Effetto teraputico lieve e non ci sono eff. Collaterali che interferiscono in misura significativa=10"
          },
          {
            value: 10,
            label:
              "Effetto teraputico lieve ma effetti collaterali che interferiscono in maniera significativa=11"
          },
          {
            value: 11,
            label:
              "Effetto terapuetico lieve ma gli effetti avversi prevalgono sul terapeutico=12"
          },
          {
            value: 12,
            label:
              "Effetto terapeutico immodificato o peggiorato e non ci sono effetti collaterali=13"
          },
          {
            value: 13,
            label:
              "Effetto teraputico immodificato o peggiorato e non ci sono eff. Collaterali che interferiscono in misura significativa=14"
          },
          {
            value: 14,
            label:
              "Effetto teraputico immodificato o peggiorato ma effetti collaterali che interferiscono in maniera significativa=15"
          },
          {
            value: 15,
            label:
              "Effetto terapuetico immodificato o peggiorato ma gli effetti avversi prevalgono sul terapeutico=16"
          }
        ]
      }
    ]
  },
  {
    type: "DBC T3",
    name: "LISTA DI CONTROLLO DELLO SVILUPPO COMPORTAMENTALE",
    codiceDataCompletamento: "DATA_DBC_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_DBC_T3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "DBC_T3_an",
        label:
          "Grado di parentela o rapporto col bambino/a del soggetto che compila il questionario",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_an1",
        label: "Il bambino è",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Incapace di vedere / incapace di sentire"
          },
          {
            value: 2,
            label: "Incapace di usare le braccia / gambe"
          },
          {
            value: 3,
            label: "Incapace di parlare/ parla molto poco"
          },
          {
            value: 4,
            label: "Affetto da altro grave disturbo medico"
          }
        ]
      },
      {
        key: "DBC_T3_an2",
        label: "Descrivete",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_an3",
        label: "Qual è la cosa che sa far meglio?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_an4",
        label: "Gli altri cosa trovano di bello o simpatico nel bambino/a?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_an5",
        label: "Quali sono le sue attività preferite?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_an6",
        label:
          "Secondo voi ci sono cose che fa bene quanto gli altri o meglio degli altri?",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_an7",
        label:
          "Avete chiesto aiuto a qualcuno per la cura dei problemi emotivi o comportamenti del bambino/a ?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "DBC_T3_an8",
        label: "Se sì, a chi l’avete chiesto?",
        if: [
          {
            key: "DBC_T3_an7",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_0",
        label:
          "Istruzioni: Le frasi sottoindicate descrivono una serie di comportamenti che possibilmente non sempre sono applicabili al bambino\ndi cui vi prendete cura. Indicate la frase che descrive il comportamento attuale del bambino o quello che avete notato\nnegli ultimi sei mesi, circolettando il numero 2 se il comportamento descritto è molto vero o capita spesso. Circolettate\nil numero 1 se il comportamento è più o meno vero o capita a volte. Circolettate lo 0 se il comportamento non è vero.\n0 = non è vero per quanto vi risulta 1 = più o meno vero o capita a volte 2 = molto vero o capita molto spesso\nSe il bambino non è in grado di fare una data cosa, circolettate 0. Per esempio, se il bambino non parla, circolettate 0 in\nrisposta alla frase “Parla troppo o parla troppo velocemente”.",
        if: null,
        type: "description"
      },
      {
        key: "DBC_T3_1",
        label: "Sembra depresso, giù di morale, scontento.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_2",
        label: "Evita di guardare gli altri direttamente negli occhi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_3",
        label: "Appare distaccato, in un mondo tutto suo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_4",
        label: "Si dimostra offensivo nel parlare, insulta gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_5",
        label: "Sistema oggetti e cose in modo preciso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_6",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_5",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_5",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_7",
        label: "Sbatte la testa.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_8",
        label: "Si eccita facilmente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_9",
        label: "Morde gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_10",
        label:
          "Non riesce a concentrarsi su un’attività per un minimo di tempo, non presta attenzione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_11",
        label: "Mastica o si mette in bocca oggetti o parti del corpo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_12",
        label:
          "Piange facilmente per un nonnulla o senza un motivo particolare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_13",
        label: "Si copre le orecchie quando sente un suono particolare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_14",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_13",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_13",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_15",
        label: 'Confonde l’uso dei pronomi, es. usa "tu" invece di "io".',
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_16",
        label: "Scappa via di proposito.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_17",
        label:
          "Illusioni: ha un’idea fissa o crede fermamente in qualcosa che non esiste.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_18",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_17",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_17",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_19",
        label: "Diventa ansioso deve rimanere solo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_20",
        label: "Non si dimostra affettuoso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_21",
        label:
          "Non reagisce alle emozioni degli altri, es. rimane impassibile se un familiare piange.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_22",
        label:
          "Si distrae facilmente da quello che sta facendo, es. a causa di un rumore.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_23",
        label: "Si lascia facilmente influenzare dagli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_24",
        label: "Mangia oggetti non commestibili, es. terra, erba, sapone.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_25",
        label:
          "Si agita eccessivamente quando è con persone che non conosce molto bene.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_26",
        label:
          "Ha paura di situazioni odi cose particolari, es. del buio o di insetti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_27",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_26",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_29",
        label: "Ha tick nervosi o fa smorfie.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_30",
        label: "Giocherella, picchietta o fa girare gli oggetti ripetutamente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_31",
        label:
          "Schizzinoso nel mangiare, oppure vuole mangiare sempre le stesse cose.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_32",
        label:
          "Divora il cibo, fa di tutto per mangiare di più, es. prende avanzi di cibo dalla pattumiera o ruba\ncose da mangiare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_33",
        label: "Si fissa su un’idea o su un’attività specifica.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_34",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_33",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_35",
        label: "Digrigna i denti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_36",
        label: "Ha incubi nel sonno. È sonnambulo/a.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_37",
        label:
          "Si arrabbia e fa i capricci. Es, pesta i piedi o sbatte la porta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_38",
        label: "Nasconde le cose.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_39",
        label: "Si picchia o si morde.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_40",
        label: "Mormora, grugnisce, si lamenta o emette altri suoni.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_41",
        label: "È impaziente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_42",
        label: "Ha un comportamento sessuale inappropriato con alter persone.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_43",
        label: "È impulsivo, agisce senza pensare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_44",
        label: "È irritabile.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_45",
        label: "È geloso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_46",
        label: "Tira calci o picchia gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_47",
        label: "Ha poca stima e fiducia di sé.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_48",
        label: "Ride o fa risolini senza un motivo apparente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_49",
        label: "Accende fuochi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_50",
        label:
          "Gli piace tenere in mano o giocare con oggetti poco comuni, es. un pezzo di spago o di legno oppure è\naffascinato da qualcosa in particolare, es. l’acqua.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_51",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_50",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_50",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_52",
        label: "Perde l’appetito.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_53",
        label: "Si masturba o espone gli organi genitali in pubblico.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_54",
        label: "Cambia di umore all’improvviso o senza un motivo apparente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_55",
        label:
          "Si muove lentamente, è poco attivo o non fa nulla. Es sta seduto a guardare gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_56",
        label: "È rumoroso o prepotente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_57",
        label: "È iperattivo, insofferente, incapace di star fermo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_58",
        label: "È estremamente affettuoso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_59",
        label:
          "Respira affannosamente, vomita soffre di mal di testa o dice di sentirsi poco bene senza motivo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_60",
        label: "Vuole essere il centro dell’attenzione, vuole farsi notare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_61",
        label: "Presta molta attenzione alle cose meccaniche.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_62",
        label: "Non avverte il pericolo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_63",
        label:
          "Preferisce la compagnia di adulti o bambini e non dei propri coetanei.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_64",
        label: "Preferisce far le cose da solo. Tende ad essere solitario.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_65",
        label: "Si preoccupa solo di una o due cose in particolare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_66",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_65",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_65",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_67",
        label:
          "Rifiuta di andare a scuola, al lavoro o al centro delle attività.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_68",
        label:
          "Fa movimenti ripetitivi con le braccia, col corpo, con la testa o con il viso. Es. Si dondola o agita le mani.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_69",
        label: "Non vuole essere abbracciato, toccato o accarezzato.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_70",
        label: "Ripete a pappagallo quello che gli dicono gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_71",
        label: "Ripete la stessa parola o frase in continuazione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_72",
        label: "Odora, assaggia o lecca oggetti di genere vario.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_73",
        label: "Si gratta o si spella.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_74",
        label: "Grida molto.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_75",
        label: "Dorme poco. Sonno disturbato o interrotto.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_76",
        label: "Fissa le luci o gli oggetti che girano.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_77",
        label: "Dorme troppo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_78",
        label:
          "Sporca il gabinetto, anche se sa come fare i sui bisogni. Gioca o imbratta muri e cose con le feci",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_79",
        label:
          "Parla sottovoce o ad alta voce o con un tono o con un ritmo insolito.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_80",
        label:
          "Spegne e accende le luci, versa acqua in continuazione o svolge attività ripetitive simili.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_81",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_80",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_80",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_82",
        label: "Ruba.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_83",
        label: "È testardo, disubbidiente e non collabora.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_84",
        label: "Si vergogna.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_85",
        label: "Si spoglia o butta via gli indumenti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_86",
        label: "Dice di saper fare cose che non è in grado di fare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_87",
        label: "Si mette vicinissimo agli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_88",
        label: "Vede o sente cose che non esistono. Ha delle allucinazioni.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_89",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_88",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_88",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_90",
        label: "Parla di suicidio.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_91",
        label: "Parla troppo o troppo in fretta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_92",
        label: "Parla da solo o a persone o ad oggetti immaginari.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_93",
        label: "Dice bugie.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_94",
        label:
          "Non collega i suoi pensieri, le sue idee sono slegate ed incoerenti ed il significato è difficile\nda seguire.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_95",
        label: "Si mostra teso, ansioso e preoccupato.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_96",
        label: "Tira o rompe oggetti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_97",
        label: "Cerca di manipolare o di provocare gli altri.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_98",
        label: "Reagisce poco al dolore.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_99",
        label:
          "Si mostra estremamente felice o contento senza un valido motivo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_100",
        label: "Fa movimenti insoliti o cammina in un modo curioso.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_101",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_100",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_100",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_102",
        label:
          "Piccoli cambiamenti della routine o dell’ambiente lo sconvolgono o lo preoccupano.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_103",
        label: "Descrivete i particolari:",
        if: [
          {
            key: "DBC_T3_102",
            comparator: "=",
            value: 1
          },
          {
            key: "DBC_T3_102",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "DBC_T3_104",
        label:
          "Urina fuori dalla tazza del gabinetto, anche se sa come deve fare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_105",
        label: "È molto prepotente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_106",
        label: "Va in giro senza meta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_107",
        label: "Piagnucola o si lamenta troppo.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_108",
        label:
          "Se il bambino ha dei problemi che non sono stati menzionati in questa lista, descriveteli",
        if: null,
        type: "free-text"
      },
      {
        key: "DBC_T3_109",
        label:
          "In linea generale, oltre ad avere problemi dello sviluppo, secondo voi il vostro bambino ha\nanche dei problemi emotivi o comportamentali? Se non ne ha circolettate 0. se ha piccoli\nproblemi, circolettate 1. se ha grossi problemi, circolettate 2.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          }
        ]
      },
      {
        key: "DBC_T3_110",
        label: "Disruptive/\nAntisocial",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_T3_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_37",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_38",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_41",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_43",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_44",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_45",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_46",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_47",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_49",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_54",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_56",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_60",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_67",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_82",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_83",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_86",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_91",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_93",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_95",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_96",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_97",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_105",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_107",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_T3_111",
        label: "Self Absorbed",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_T3_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_24",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_29",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_30",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_32",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_35",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_39",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_40",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_48",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_50",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_53",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_62",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_68",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_72",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_74",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_76",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_78",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_80",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_85",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_96",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_98",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_100",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_104",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_106",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_T3_112",
        label: "Communication \nDisturbance",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_T3_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_33",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_58",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_61",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_63",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_65",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_70",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_71",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_79",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_87",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_92",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_99",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_T3_113",
        label: "Anxiety",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_T3_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_25",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_26",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_31",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_36",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_52",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_84",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_102",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_T3_114",
        label: "Social Relating",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_T3_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_55",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_59",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_64",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_69",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_77",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      },
      {
        key: "DBC_T3_115",
        label: "Total Behaviour Problem Score",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "DBC_T3_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_17",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_22",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_24",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_25",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_26",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_29",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_30",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_31",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_32",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_33",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_35",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_36",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_37",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_38",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_39",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_40",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_41",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_42",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_43",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_44",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_45",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_46",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_47",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_48",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_49",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_50",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_52",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_53",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_54",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_55",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_56",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_57",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_58",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_59",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_60",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_61",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_62",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_63",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_64",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_65",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_67",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_68",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_69",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_70",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_71",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_72",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_73",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_74",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_75",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_76",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_77",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_78",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_79",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_80",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_82",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_83",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_84",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_85",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_86",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_87",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_88",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_90",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_91",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_92",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_93",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_94",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_95",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_96",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_97",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_98",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_99",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_100",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_102",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_104",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_105",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_106",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_107",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            },
            {
              key: "DBC_T3_109",
              map: {
                "0": 0,
                "1": 1,
                "2": 2
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "PPC T3",
    name: "Parent Problem CheckList (PPC_T3)",
    codiceDataCompletamento: "DATA_PPC_T3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_PPC_T3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "PPC_T3_0",
        label:
          "Indichi il numero che meglio descrive la misura in cui questo è stato un problema per lei e il suo partner nelle ultime 4 settimane. Indichi zero se il problema non si è presentato ",
        if: null,
        type: "description"
      },
      {
        key: "PPC_T3_1",
        label:
          "Disaccordo sulle regole domestiche (ad es.\nora di andare a dormire, spazi di gioco)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_2",
        label:
          "Disaccordo sul tipo di disciplina (ad es. dare\nschiaffi ai bambini)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_3",
        label: "Disaccordo su chi dovrebbe insegnare la\ndisciplina ai bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_4",
        label: "Litigare di fronte ai bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_5",
        label: "Mancanza di accordo tra i genitori",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_6",
        label: "Non poter restare soli a causa dei bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_7",
        label:
          "Disaccordo sulla suddivisione del carico di\nlavoro nell’accudimento dei bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_8",
        label:
          "Incapacità di risolvere i conflitti riguardo\nall’accudimento dei bambini",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_9",
        label:
          "Le discussioni riguardo all'accudimento dei\nfigli diventano litigi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_10",
        label: "Un genitore ostacola l'altro, ad es. non\nappoggiandolo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_11",
        label: "Un genitore favorisce un figlio rispetto\nall'altro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_12",
        label:
          "Mancanza di dialogo tra genitori in merito all’\naccudimento dei figli",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_13",
        label: "Mancanza di dialogo in generale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_14",
        label: "Un genitore è più “morbido”, l'altro più\nsevero coi bambini ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_15",
        label:
          "I bambini si comportano peggio con un\ngenitore rispetto all'altro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_16",
        label:
          "Disaccordo su cosa si considera come\ncomportamento “disubbidiente”",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: 6
          },
          {
            value: 7,
            label: 7
          }
        ]
      },
      {
        key: "PPC_T3_18",
        label: "PUNTEGGIO TOTALE ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "PPC_T3_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_14",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            },
            {
              key: "PPC_T3_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "Tanner T3",
    name: "Parent Problem CheckList (Tanner_T3)",
    codiceDataCompletamento: "DATA_Tanner_T3_04",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_Tanner_T3_04",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "Tanner_T3_Sex",
        label: "Sesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Maschio"
          },
          {
            value: 1,
            label: "Femmina"
          }
        ]
      },
      {
        key: "Tanner_T3_0",
        label: "Peluria",
        if: [
          {
            key: "Tanner_T3_Sex",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      },
      {
        key: "Tanner_T3_1",
        label: "Mammelle ",
        if: [
          {
            key: "Tanner_T3_Sex",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      },
      {
        key: "Tanner_T3_2",
        label: "Genitali",
        if: [
          {
            key: "Tanner_T3_Sex",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      },
      {
        key: "Tanner_T3_3",
        label: "Peluria",
        if: [
          {
            key: "Tanner_T3_Sex",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Stadio 1"
          },
          {
            value: 1,
            label: "Stadio 2"
          },
          {
            value: 2,
            label: "Stadio 3"
          },
          {
            value: 3,
            label: "Stadio 4"
          },
          {
            value: 4,
            label: "Stadio 5"
          }
        ]
      }
    ]
  },
  {
    type: "PedS T3",
    name: "peds_t3QL: Questionario sulla qualità della vita in età pediatrica",
    codiceDataCompletamento: "DATA_peds_t3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_peds_t3",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "peds_t3_0",
        label:
          "Nella pagina che segue c’è una lista di cose che potrebbero essere un problema\nper Suo/a figlio/a. Considerando tutto quello che è successo nell'ULTIMO mese, ci dica quanto\nciascuna di queste cose è un problema per Suo/a figlio/a, facendo una\ncrocetta su: 0 se non è mai un problema, 1 se non è quasi mai un problema, 2 se è qualche volta un problema, 3 se è spesso un problema, 4 se è quasi sempre un problema\n\nNon ci sono risposte giuste o sbagliate. Se non capisce una domanda, per favore chieda aiuto.\n",
        if: null,
        type: "description"
      },
      {
        key: "peds_t3_1",
        label: "1. Camminare per più di 100 metri",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_2",
        label: "2. Correre",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_3",
        label: "3. Fare sport o attività fisica",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_4",
        label: "4. Sollevare qualcosa di pesante",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_5",
        label: "5. Fare il bagno o la doccia da solo/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_6",
        label: "6. Fare dei lavoretti di casa",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_7",
        label: "7. Avere male o dolore",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_8",
        label: "8. Avere un basso livello di energia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_9",
        label: "1. Essere impaurito/a o spaventato/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_10",
        label: "2. Sentirsi triste",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_11",
        label: "3. Essere arrabbiato/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_12",
        label: "4. Avere difficoltà ad addormentarsi e/o a dormire",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_13",
        label: "5. Preoccuparsi per il proprio futuro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_14",
        label: "1. Andare d’accordo con gli/le altri/e ragazzini/e",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_15",
        label:
          "2. Gli/Le altri/e ragazzini/e non vogliono essere suoi/sue amici/che",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_16",
        label: "3. Essere preso/a in giro dagli/dalle altri/e ragazzini/e",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_17",
        label:
          "4. Non riuscire a fare quello che gli/le altri/e ragazzini/e della sua età riescono a fare",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_18",
        label:
          "5. Riuscire a fare quello che fanno gli/le altri/e ragazzini/e quando gioca con loro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_19",
        label: "1. Stare attento/a in classe",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_20",
        label: "2. Dimenticare le cose",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_21",
        label:
          "3. Riuscire a seguire le lezioni in classe e a finire i compiti a casa regolarmente",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_22",
        label: "4. Non andare a scuola perché non si sente bene",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_23",
        label: "5. Non andare a scuola per andare dal dottore o in ospedale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          }
        ]
      },
      {
        key: "peds_t3_24",
        label: "FUNZIONAMENTO FISICO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_t3_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      },
      {
        key: "peds_t3_25",
        label: "FUNZIONAMENTOEMOTIVO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_t3_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      },
      {
        key: "peds_t3_26",
        label: "FUNZIONAMENTO SOCIALE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_t3_14",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_17",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_18",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      },
      {
        key: "peds_t3_27",
        label: "FUNZIONAMENTO SCOLASTICO",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "peds_t3_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_22",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "peds_t3_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "SDQ T3",
    name: "SDQ",
    codiceDataCompletamento: "DATA_SDQ_t3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_SDQ_t3",
        label: "Data di completamento",
        type: "date"
      },
      {
        key: "SDQ_1_t3",
        label: "Punteggio totale delle difficoltà",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_2_t3",
        label: "Punteggio sintomi emotivi",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_3_t3",
        label: "Punteggio disturbi delle condotta",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_4_t3",
        label: "Punteggio iperattività",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_5_t3",
        label: "Punteggio problemi di socializzazione ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_6_t3",
        label: "Punteggio comportamento prosociale ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SDQ_7_t3",
        label: "Punteggio impatto ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "DAWBA T3",
    name: "DAWBA",
    codiceDataCompletamento: "DATA_DAWBA_t3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_DAWBA_t3",
        label: "Data di completamento",
        type: "date"
      },
      {
        key: "DAWBA_1_t3",
        label: "Problemi medici",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_2_t3",
        label: "Eventi di vita stressanti",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_3_t3",
        label: "Stress Familiari ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_4_t3",
        label: "Parenting",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_5_t3",
        label: "Stress intervistato",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "DAWBA_6_t3",
        label: "Stress partner",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 150
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "CBCL T3",
    name: "QUESTIONARIO SUL COMPORTAMENTO DEL BAMBINO ",
    codiceDataCompletamento: "DATA_CBCA_T3_",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATA_CBCA_T3_",
        label: "DATA",
        type: "date"
      },
      {
        key: "CBCA_T3__1",
        label: "Scala Activities Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__2",
        label: "Scala Activities T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__3",
        label: "\n\nScala Social raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__4",
        label: "\n\nScala Social T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__5",
        label: "Scala School Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__6",
        label: "Scala School T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__7",
        label: "Total Comp Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__8",
        label: "Total Comp T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__9",
        label: "Anxious/Depressed Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__10",
        label: "Anxious/Depressed T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__11",
        label: "Withdrawn/Depressed Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__12",
        label: "Withdrawn/Depressed T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__13",
        label: "Somatic Complaints Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__14",
        label: "Somatic Complaints T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__15",
        label: "Social Problems Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__16",
        label: "Social Problems T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__17",
        label: "Thought Problems Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__18",
        label: "Thought Problems T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__19",
        label: "Attention Problems Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__20",
        label: "Attention Problems T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__21",
        label: "Rule-Breaking Behavior Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__22",
        label: "Rule-Breaking Behavior T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__23",
        label: "Aggressive Behavior Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__24",
        label: "Aggressive Behavior T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__25",
        label: "INT Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__26",
        label: "INT T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__27",
        label: "EXT Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__28",
        label: "EXT T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__30",
        label: "TOT Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__31",
        label: "TOT T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__32",
        label: "DSM-1 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__33",
        label: "DSM-1 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__34",
        label: "DSM-2 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__35",
        label: "DSM-2 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__36",
        label: "DSM-3 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__37",
        label: "DSM-3 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__38",
        label: "DSM-4 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__39",
        label: "DSM-4 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__40",
        label: "DSM-5 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__41",
        label: "DSM-5 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__42",
        label: "DSM-6 Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__43",
        label: "DSM-6 T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__44",
        label: "Sluggish Cognitive Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__45",
        label: "Sluggish Cognitive T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__46",
        label: "Obsessive-compulsive Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__47",
        label: "Obsessive-compulsive T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__48",
        label: "Stress Raw",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "CBCA_T3__49",
        label: "Stress T",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "BIOMARKERS T3",
    name: "BIOMARKERS",
    codiceDataCompletamento: "DATE_b_t3",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Adulto"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Paziente Pediatrico"
      }
    ],
    domande: [
      {
        key: "DATE_b_t3",
        label: "Date ",
        type: "date"
      },
      {
        key: "BIOMARKERS_1_t3",
        label: "HbA1c",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_2_t3",
        label: "Glicemia basale",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_3_t3",
        label: "Glicemia dopo 120 minuti",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_7_t3",
        label: "AST",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_8_t3",
        label: "ALT",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_9_t3",
        label: "Gamma GT",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_10_t3",
        label: "Trigliceridi",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_11_t3",
        label: "Colesterolo totale",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_12_t3",
        label: "HDL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_13_t3",
        label: "LDL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_14_t3",
        label: "LP (a)",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_15_t3",
        label: "Apo A1",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_16_t3",
        label: "Apo B",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_17_t3",
        label: "Insulina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_18_t3",
        label: "C Peptide",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_19_t3",
        label: "Zonulina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_20_t3",
        label: "Adiponectina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_21_t3",
        label: "Leptina",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_22_t3",
        label: "AB-TPO",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_23_t3",
        label: "AB-TG",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_24_t3",
        label: "TSH",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_25_t3",
        label: "FT4",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_26_t3",
        label: "Vitamina D",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_27_t3",
        label: "Protein glycation derangements ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_28_t3",
        label: "Zinc-alfa2-glycoprotein ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_29_t3",
        label: "Fetuin-A glycoprotein",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_30_t3",
        label: "P-selectin glycoprotein ",
        if: null,
        type: "free-text"
      }
    ]
  }
];
