import React from "react";
import { Component } from "react";
import { get, map } from "lodash";
import { centroHaControlli } from "common/utils/codiceCentro.js";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import fpSet from "lodash/fp/set";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  getCompletamentoQuestionariPerUtente,
  creaPaziente,
  creaOperatore
} from "app/actions";
import EsmActigrafo from "app/elements/EsmActigrafo.js";
import HaDatiActigrafo from "app/elements/HaDatiActigrafo.js";
import RaccoltaDatiCompletata from "app/elements/RaccoltaDatiCompletata.js";
import StatoQuestionari from "app/elements/StatoQuestionari.js";
import { Link } from "react-router-dom";
import ControlloForm from "app/components/ControlloForm.js";
import PazienteForm from "app/components/PazienteForm.js";
import OperatoreForm from "app/components/OperatoreForm.js";
import CreateRedirectPopup from "app/elements/onCreateRedirect";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { tipiControlli } from "common/tipiPazienti";
import { NUMERO_DI_VOLTE_ESM } from "common/datiPortale";

class AbstractListaPartecipanti extends Component {
  /*
    PROPS:
    getPartecipanti
    isPazienti
    isControlli
    isOperatori
    */
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderCreateRedirectPopup = () => {
    const createRedirectProps = this.state.createRedirectProps || {};
    return (
      <CreateRedirectPopup
        {...createRedirectProps}
        onEnd={() => {
          //nothing...
        }}
      />
    );
  };

  onCreateRedirect = (user, history) => {
    this.setState({
      ...this.state,
      createRedirectProps: {
        user,
        history,
        isOpen: true
      }
    });
  };

  creaPaziente = () => {
    this.props
      .creaPaziente(get(this.state, "creaPazienteModal.user"))
      .then(action => {
        if (action.callApiSuccess) {
          this.onCreateRedirect(
            get(action, "response.data"),
            get(this.props, "history")
          );
        } else {
          this.setState(
            fpSet(
              "creaPazienteModal.error",
              get(action, "response"),
              this.state
            )
          );
        }
      });
  };

  creaOperatore = () => {
    this.props
      .creaOperatore(get(this.state, "creaOperatoreModal.user"))
      .then(action => {
        if (action.callApiSuccess) {
          this.onCreateRedirect(
            get(action, "response.data"),
            get(this.props, "history")
          );
        } else {
          this.setState(
            fpSet(
              "creaOperatoreModal.error",
              get(action, "response"),
              this.state
            )
          );
        }
      });
  };

  render() {
    const { t, isPazienti, isControlli, isOperatori } = this.props;

    if (
      isControlli &&
      !(
        get(this.props, "user.userType") === "Admin" ||
        centroHaControlli(get(this.props, "user.codiceCentro"))
      )
    ) {
      return (
        <Row>
          <Col sm={12}>
            <Alert color="warning">
              {t("error_non_abilitato_aggiunta_controlli")}
            </Alert>
          </Col>
        </Row>
      );
    }

    const canCreateOperatore = !!(
      get(this.props, "user.userType") === "Admin" ||
      get(this.props, "user.adminCentro")
    );

    const PartecipanteForm = isPazienti ? PazienteForm : ControlloForm;

    return (
      <>
        <Row>
          <Col sm={12}>
            <Card>
              <CardHeader>
                {isPazienti
                  ? t("lista_pazienti_label")
                  : isControlli
                  ? t("lista_controlli_label")
                  : isOperatori
                  ? t("lista_operatori_label")
                  : ""}{" "}
              </CardHeader>

              <CardBody>
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    {(!isOperatori || canCreateOperatore) && (
                      <Button
                        color="primary"
                        onClick={() => {
                          if (isOperatori) {
                            this.setState(
                              fpSet(
                                "creaOperatoreModal",
                                { user: { userType: "Operatore" } },
                                this.state
                              )
                            );
                          } else {
                            this.setState(
                              fpSet(
                                "creaPazienteModal",
                                {
                                  user:
                                    isControlli && tipiControlli.length === 1
                                      ? {
                                          userType: tipiControlli[0],
                                          partecipaObiettivo2: true
                                        }
                                      : {}
                                },
                                this.state
                              )
                            );
                          }
                        }}
                      >
                        {isPazienti
                          ? t("crea_paziente_label")
                          : isControlli
                          ? t("crea_controllo_label")
                          : isOperatori
                          ? t("crea_operatore_label")
                          : ""}
                      </Button>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LegendaColoriQuestionari />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <AsyncData loaderAction={this.props.getPartecipanti}>
                      {({ loading, loadingError, data }) => {
                        console.log({ loading, loadingError, data });
                        if (loading) {
                          return <Loading />;
                        }
                        if (loadingError) {
                          return (
                            <Alert color="danger">{loadingError.message}</Alert>
                          );
                        }
                        return (
                          <Table responsive={true} bordered={true}>
                            <thead>
                              <tr>
                                <th>N </th>
                                <th>
                                  {t(
                                    "lista_partecipanti_table_col_codice_utente"
                                  )}{" "}
                                </th>
                                <th>
                                  {t(
                                    "lista_partecipanti_table_col_tipo_utente"
                                  )}{" "}
                                </th>
                                <th>
                                  {t("lista_partecipanti_table_col_eta")}{" "}
                                </th>
                                <th>
                                  {t("lista_partecipanti_table_col_genere")}{" "}
                                </th>
                                {!isOperatori && (
                                  <th>
                                    {t(
                                      "lista_partecipanti_table_col_esm_actigrafo"
                                    )}{" "}
                                  </th>
                                )}
                                {!isOperatori && (
                                  <th>
                                    {t(
                                      "lista_partecipanti_table_col_dati_actigrafo"
                                    )}{" "}
                                  </th>
                                )}
                                <th>
                                  {t(
                                    "lista_partecipanti_table_col_raccolta_completata"
                                  )}{" "}
                                </th>
                                <th>
                                  {t("lista_partecipanti_table_col_stato")}{" "}
                                </th>
                                <th style={{ width: 150 }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {map(data, (row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>{rowIndex + 1}</td>
                                  <td>{row.codiceUtente}</td>
                                  {/* col userType */}
                                  {isOperatori ? (
                                    <td>
                                      {get(row, "userType")}{" "}
                                      {get(row, "adminCentro")
                                        ? "(Responsabile)"
                                        : null}
                                    </td>
                                  ) : (
                                    <td>{row.userType}</td>
                                  )}
                                  <td>{row.etaAllInizio}</td>
                                  <td>{row.genere}</td>
                                  {!isOperatori && (
                                    <td>
                                      <EsmActigrafo user={row} />
                                    </td>
                                  )}
                                  {!isOperatori && (
                                    <td>
                                      {new Array(NUMERO_DI_VOLTE_ESM)
                                        .fill(0)
                                        .map((_, index) => {
                                          return (
                                            <HaDatiActigrafo
                                              key={index}
                                              user={row}
                                              fileActigrafoGrezziPathIndex={
                                                index
                                              }
                                            />
                                          );
                                        })}
                                    </td>
                                  )}
                                  <td>
                                    <RaccoltaDatiCompletata user={row} />
                                  </td>
                                  <td>
                                    <AsyncData
                                      loaderAction={
                                        this.props
                                          .getCompletamentoQuestionariPerUtente
                                      }
                                      loaderArgs={[get(row, "id")]}
                                    >
                                      {({ loading, loadingError, data }) => {
                                        console.log({
                                          loading,
                                          loadingError,
                                          data
                                        });
                                        if (loading) {
                                          return <Loading />;
                                        }
                                        if (loadingError) {
                                          return (
                                            <Alert color="danger">
                                              {loadingError.message}
                                            </Alert>
                                          );
                                        }
                                        return (
                                          <StatoQuestionari
                                            questionariCompletamento={data}
                                            user={row}
                                          />
                                        );
                                      }}
                                    </AsyncData>
                                  </td>
                                  <td>
                                    <Button
                                      className=""
                                      color="primary"
                                      size="sm"
                                      outline={true}
                                      tag={Link}
                                      to={
                                        (isPazienti
                                          ? "/pazienti/"
                                          : isControlli
                                          ? "/controlli/"
                                          : isOperatori
                                          ? "/operatori/"
                                          : "") +
                                        get(row, "id") +
                                        "/dettaglio"
                                      }
                                    >
                                      {isPazienti
                                        ? t("dettaglio_paziente_label")
                                        : isControlli
                                        ? t("dettaglio_partecipante_label")
                                        : isOperatori
                                        ? t("dettaglio_operatore_label")
                                        : ""}
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        );
                      }}
                    </AsyncData>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {(isPazienti || isControlli) && (
            <Modal size="lg" isOpen={!!get(this.state, "creaPazienteModal")}>
              <ModalHeader
                toggle={() => {
                  this.setState(fpSet("creaPazienteModal", false, this.state));
                }}
              >
                {!!get(this.state, "creaPazienteModal") &&
                  (isPazienti
                    ? t("crea_paziente_label")
                    : t("crea_controllo_label"))}
              </ModalHeader>

              <ModalBody>
                {!!get(this.state, "creaPazienteModal") && (
                  <>
                    <PartecipanteForm
                      paziente={get(this.state, "creaPazienteModal.user")}
                      updatePaziente={obj => {
                        this.setState(
                          fpSet("creaPazienteModal.user", obj, this.state)
                        );
                      }}
                      pazienteErrors={get(
                        this.state,
                        "creaPazienteModal.error"
                      )}
                      isCreate={true}
                    />
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                {!!get(this.state, "creaPazienteModal") && (
                  <>
                    {get(this.state, "creaPazienteModal.error") &&
                    get(this.state, "creaPazienteModal.error").message ? (
                      <div className="text-danger">
                        {get(this.state, "creaPazienteModal.error").message}
                      </div>
                    ) : null}

                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState(
                          fpSet("creaPazienteModal", false, this.state)
                        );
                      }}
                    >
                      {t("annulla_label")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => {
                        this.creaPaziente();
                      }}
                    >
                      {isPazienti
                        ? t("crea_paziente_label")
                        : t("crea_controllo_label")}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Modal>
          )}

          {isOperatori && (
            <Modal size="lg" isOpen={!!get(this.state, "creaOperatoreModal")}>
              <ModalHeader
                toggle={() => {
                  this.setState(fpSet("creaOperatoreModal", false, this.state));
                }}
              >
                {!!get(this.state, "creaOperatoreModal") &&
                  t("crea_operatore_label")}
              </ModalHeader>

              <ModalBody>
                {!!get(this.state, "creaOperatoreModal") && (
                  <>
                    <OperatoreForm
                      operatore={get(this.state, "creaOperatoreModal.user")}
                      updateOperatore={obj => {
                        this.setState(
                          fpSet("creaOperatoreModal.user", obj, this.state)
                        );
                      }}
                      operatoreErrors={get(
                        this.state,
                        "creaOperatoreModal.error"
                      )}
                      isCreate={true}
                    />
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                {!!get(this.state, "creaOperatoreModal") && (
                  <>
                    {get(this.state, "creaOperatoreModal.error") &&
                    get(this.state, "creaOperatoreModal.error").message ? (
                      <div className="text-danger">
                        {get(this.state, "creaOperatoreModal.error").message}
                      </div>
                    ) : null}

                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState(
                          fpSet("creaOperatoreModal", false, this.state)
                        );
                      }}
                    >
                      {t("annulla_label")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => {
                        this.creaOperatore();
                      }}
                    >
                      {t("crea_operatore_label")}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Modal>
          )}
        </Row>

        {this.renderCreateRedirectPopup()}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getCompletamentoQuestionariPerUtente: getCompletamentoQuestionariPerUtente,
  creaPaziente: creaPaziente,
  creaOperatore: creaOperatore
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AbstractListaPartecipanti)
  )
);
